import Header from 'components/Headers/Header';
import { api } from 'Interceptor';
import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useSelector } from 'react-redux';
import { CardHeader } from 'reactstrap';
import CreateCategoryModal from '../../components/Modal.js/ArticleModal'
import moment from "moment";
import {customDateFormat} from "../../Helper/Functions";
import { couldStartTrivia } from 'typescript';

const ViewPayments = () => {
  const [paymentList, setPaymentList] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [filter, setFilter] = useState('all');
  const { modalReducer } = useSelector(state => state)
  
  const formatNumber = (number) => {
    return new Intl.NumberFormat('id').format(number);
  } 

  const ExportButton = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <Button color="primary" className="ml-3" onClick={ handleClick }>Export to CSV</Button>
    );
  };
  
  async function getData() {
    let countRevenue = 0;
    const endpoint = filter == 'all' ? `/payments` : `/payments/report/${filter}`;
    const { data: { payments } } = await api.headers().method('get', endpoint)
    payments.map((cat, id) => {
      countRevenue += cat.status === "completed" || filter !== 'all' ? parseInt(cat.amountPaid) : 0; 
      cat.createdAt = filter === 'monthly' ? customDateFormat(cat?.createdAt) : moment(cat?.createdAt).format('DD-MMM-YYYY');
      cat.PayeeName = <>{cat.payeeName}</>;
      cat['Email'] = cat?.user?.email;
      cat['AmountPaid'] = cat.status === "completed" || filter !== 'all' ? <>IDR <strong style={{fontWeight: 700, color: "#ffbe00",}}>{formatNumber(cat.amountPaid)}</strong></> : "-" ;
      cat['planName'] = cat?.plan?.displayName;
      cat['CoinsEarned'] = cat.status === "completed" || filter !== 'all' ? <><strong style={{fontWeight: 700, color: "#ffbe00",}}>{formatNumber(cat.coinsEarned)}</strong> Chapters</> : "-";
      cat.Status = <span className={`badge ${cat.status !== "completed" ? cat.status === "pending" ? "badge-neutral" : "badge-danger" : "badge-success"}`}>{cat.status}</span>
      return cat
    })
    setPaymentList(payments)
    setTotalRevenue(formatNumber(countRevenue));
  }
  useEffect(() => {
    getData()
  }, [modalReducer, filter]);
  let columns = [
    {
      dataField: 'createdAt',
      text: 'Date',
    },
    {
      dataField: 'PayeeName',
      text: 'Name',
      csvFormatter: (cell, row, rowIndex) => `${row.payeeName}`
    },
    {
      dataField: 'Email',
      text: 'email',
    },
    {
      dataField: 'AmountPaid',
      text: 'Price',
      csvFormatter: (cell, row, rowIndex) => `${row.amountPaid || "-"}`
    },
    {
      dataField: 'planName',
      text: 'Plan',
    },
    {
      dataField: 'CoinsEarned',
      text: 'Free Chapters',
      csvFormatter: (cell, row, rowIndex) => `${row.coinsEarned || "-"}`
    },
    {
      dataField: 'referenceNumber',
      text: 'Reference Number',
    },
    {
      dataField: 'Status',
      text: 'Status',
      csvFormatter: (cell, row, rowIndex) => `${row.status}`
    },
  ]

  if (filter !== "all") {
    columns = [
      {
        dataField: 'createdAt',
        text: 'Date',
      },
      {
        dataField: 'AmountPaid',
        text: 'Price',
        csvFormatter: (cell, row, rowIndex) => `${row.amountPaid || "-"}`
      },
      {
        dataField: 'CoinsEarned',
        text: 'Free Chapters',
        csvFormatter: (cell, row, rowIndex) => `${row.coinsEarned || "-"}`
      },
    ]
  }

  return <>
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col-12">
            <ul className="justify-content-start mb-4 nav nav-pills">
                <li className="mr-2 mr-md-0 nav-item">
                  <a href="#" className={`py-2 px-3 nav-link ${filter == 'all' ? '' : 'active'}`} onClick={() => setFilter('all') }>
                    <span class="d-none d-md-block">All</span>
                  </a>
                </li>
                <li className="mr-2 mr-md-0 nav-item">
                  <a href="#" className={`py-2 px-3 nav-link ${filter == 'daily' ? '' : 'active'}`} onClick={() => setFilter('daily') }>
                    <span class="d-none d-md-block">Daily</span>
                  </a>
                </li>
                <li className="mr-2 mr-md-0 nav-item">
                  <a href="#" className={`py-2 px-3 nav-link ${filter == 'monthly' ? '' : 'active'}`} onClick={() => setFilter('monthly') }>
                    <span class="d-none d-md-block">Monthly</span>
                  </a>
                </li>
              </ul>
          </div>
          <div className="col">
            <Card className="bg-default shadow ">
            <ToolkitProvider
                keyField="id"
                data={paymentList} 
                columns={columns}
                exportCSV
              >
                {
                  props => (
                    <>
                    <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                      <h3 className="text-white mb-0">Payment History</h3>

                      <div className='d-flex align-items-center'>
                        <div className="total-amount">
                          IDR <strong style={{fontWeight: 700, color: "#ffbe00",}}>{totalRevenue}</strong>
                        </div>
                        <ExportButton { ...props.csvProps }></ExportButton>
                      </div>
                    </CardHeader>
                    <BootstrapTable { ...props.baseProps } bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id"
                      noDataIndication='No Records Found'
                    />
                    </>
                  )
                }
              </ToolkitProvider>

            </Card>
          </div>
        </Row>
      </Container>
    </div>
    <CreateCategoryModal />
  </>
    ;
};

export default ViewPayments;
