import React from "react";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar, Doughnut, Pie } from "react-chartjs-2";
// reactstrap components
import { Card, CardHeader, CardBody } from "reactstrap";
// core components
import {
  // global options for the charts
  chartOptions,
  // function that adds the global options to our charts
  parseOptions,
  chartExample2,
  chartExample1,
} from "../../variables/charts.js";
import { useEffect } from "react";
import Header from "components/Headers/Header";
import { Col, Container, Row } from "react-bootstrap";
import { api } from "Interceptor/index.js";
import { useState } from "react";
import { showNotification } from "Helper/Functions";

const Analytics = () => {
  const [topRatedArticles, setTopRatedArticles] = useState([])
  const [totalSpent, setTotalSpent] = useState([])
  const [topRatedAuthors, setTopRatedAuthors] = useState([])
  const [trendingArticles, setTrendingArticles] = useState([])
  const [daysType, setDaysType] = useState(
    [
      {
        name: "Week",
        type: true,
        days : "7"
      },
      {
        name: "14 Days",
        type: false,
        days : "14"
      },
      {
        name: "Month",
        type: false,
        days : "30"
      },
      {
      name: "60 Days",
      type: false,
      days : "60"
    }]
    
  )

  const colors = [
    {
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgb(255, 99, 132)',
    },
    {
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgb(53, 162, 235)',
    },
    {
      borderColor: '#5e72e4',
      backgroundColor: '#5e72e4',
    },
    {
      borderColor: 'rgb(245, 54, 92)',
      backgroundColor: 'rgb(245, 54, 92)',
    },
    {
      borderColor: '#663A3A',
      backgroundColor: '#663A3A',
    },
    {
      borderColor: '#2dce89',
      backgroundColor: '#2dce89',
    },
    {
      borderColor: '#FFFB00',
      backgroundColor: '#FFFB00',
    },
    {
      borderColor: '#F000FF',
      backgroundColor: '#F000FF',
    },
    {
      borderColor: '#fb6340',
      backgroundColor: '#fb6340',
    },
    {
      borderColor: '#11cdef',
      backgroundColor: '#11cdef',
    },
    {
      borderColor: '#172b4d',
      backgroundColor: '#172b4d',
    },
    {
      borderColor: '#f5365c',
      backgroundColor: '#f5365c',
    },
    {
      borderColor: '#347C2C',
      backgroundColor: '#347C2C',
    },
    {
      borderColor: '#ffff00',
      backgroundColor: '	#ffff00',
    },
    {
      borderColor: '#01FAE1',
      backgroundColor: '#01FAE1',
    },
    {
      borderColor: '#CBCB3A',
      backgroundColor: '#CBCB3A',
    },
    {
      borderColor: '#CB3AA0',
      backgroundColor: '#CB3AA0',
    },
    {
      borderColor: '#6B8686',
      backgroundColor: '#6B8686',
    },
    {
      borderColor: '#12C7F8',
      backgroundColor: '#12C7F8',
    },
    {
      borderColor: '#F8122A',
      backgroundColor: '#F8122A',
    },
    {
      borderColor: '#172453',
      backgroundColor: '#172453',
    },
  ]


  parseOptions(Chart, chartOptions());

  useEffect(() => {
    api.headers().method('get', '/reports/summary', null, { params: { interval: daysType.find(x => x.type == true)?.days } }).then((response) => {
      setTopRatedArticles(response?.data?.topRatedArticles)
      setTopRatedAuthors(response?.data?.topRatedAuthors)
      setTotalSpent(response?.data?.totalSpent)
      setTrendingArticles(response?.data?.trendingArticles)
    }).catch((er) => showNotification(er))
  }, [daysType])
  const dataTopRatedArticles = topRatedArticles?.map((x) => x?.rating.toFixed(2))
  const labelTopRatedArticles = topRatedArticles?.map((x) => x?.title)
  const dataTopRatedAuthors = topRatedAuthors?.map((x) => x?.rating.toFixed(2));
  const labelTopRatedAuthors = topRatedAuthors?.map((x) => x?.userData?.fullName)
  const dataTotalSpent = totalSpent?.map((x) => x?.totalSpent.toFixed(2))
  const labelTotalSpent = totalSpent?.map((x) => x?.userData?.fullName)


  const datesTrendingArticles = [...new Set(trendingArticles?.map(data => data?.date))]

  var uniqueDates = [];
  var datasets = [];
  // Just Have have Uniqueness
  for (let index = 0; index < trendingArticles.length; index++) { // 6 Runs
    const element = trendingArticles[index];

    // Unique Dates
    if (!uniqueDates.includes(element.date)) {
      uniqueDates.push(element.date);
    }

    const foundData = datasets.find(function (d) {
      return d.label == element.title ? true : false;
    })
    // Unique Dataset based on article title / id
    if (!foundData) {
      datasets.push({
        label: element.title,
        data: [],
        borderColor: colors[index]?.borderColor ? colors[index]?.borderColor : '#000',
        pointBackgroundColor: colors[index]?.borderColor ? colors[index]?.borderColor : '#000',
      });
    }
  }
  for (let index = 0; index < datasets.length; index++) { // 5 Runs
    const element = datasets[index];

    for (let j = 0; j < uniqueDates.length; j++) { // 2 Runs
      const elementDate = uniqueDates[j];
      let value = 0;

      const findingExactMatch = trendingArticles.find(function (params) { // 6 Runs but breaks as soon as data is found
        return params.date == elementDate && params.title == element.label;
      })
      if (findingExactMatch) {
        value = findingExactMatch.totalViews;
      }

      element.data[j] = value;
    }

  }


  let chartTopRatedArticles = {
    options: {
    },
    data: {
      labels: [...labelTopRatedArticles],
      datasets: [
        {
          label: "Top Rated",
          data: [...dataTopRatedArticles],
          maxBarThickness: 10,
          backgroundColor: labelTopRatedArticles.map((x, index) => colors[index]?.backgroundColor),
          borderColor: "#172b4d",
        },
      ],
    },
  };

  let chartTopRatedAuthors = {
    options: {
    },
    data: {
      labels: [...labelTopRatedAuthors],
      datasets: [
        {
          label: "Top Rated",
          data: [...dataTopRatedAuthors],
          maxBarThickness: 10,
          backgroundColor: labelTopRatedAuthors.map((x, index) => colors[index]?.backgroundColor),
          borderColor: "#172b4d",
        },
      ],
    },
  };

  let chartTotalSpent = {
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              },
            },
          },
        ],
      },
    },
    data: {
      labels: [...labelTotalSpent],
      datasets: [
        {
          label: "Coins",
          data: [...dataTotalSpent],
          maxBarThickness: 10,

        },
      ],
    },
  };
  let chartTrendingArticles = {
    data: {
      labels: [...datesTrendingArticles],
      datasets: [...datasets],
    },


  };

  const activateButton = (index) => {
    const currentlyActive = daysType.find(x => x.type == true);

    currentlyActive.type = false;
    daysType[index].type = true;
    setDaysType([...daysType]);
  }


  return (
    <>
      <Header />

      <Container className="mt--7" fluid>

        <Row>
          <Col lg={12}>
            <ul class="justify-content-start mb-4 nav nav-pills">
              {
                daysType?.map((x, i) => {
                  return (
                    <li class="mr-2 mr-md-0 nav-item">
                      <a href="#" class={x?.type == true ? "py-2 px-3 nav-link" : "py-2 px-3 active nav-link"} onClick={() => {
                        activateButton(i)
                      }}>
                        {x.name}
                        
                      </a>
                    </li>
                  )
                })
              }
             
            </ul>
          </Col>
          {/* <Col lg={6}>
            <Card className="bg-default mb-3">

              <CardHeader className="bg-default">
                <h5 className="text-white h3 mb-0">Most Viewed Articles</h5>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={chartExample2.data}
                    options={chartExample2.options}
                    className="chart-canvas"
                    id="chart-bars"
                  />
                </div>
              </CardBody>
            </Card>
          </Col> */}
          <Col lg={12}>
            <Card className="bg-default mb-3">
              <CardHeader className="bg-default">
                <h5 className="text-white h3 mb-0">Trending Novels</h5>

              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={chartTrendingArticles.data}
                    options={chartTrendingArticles.options}
                    id="chart-sales"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="bg-default mb-3">
              <CardHeader className="bg-default">
                <h5 className="text-white h3 mb-0">Top Rated Novels</h5>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Doughnut
                    data={chartTopRatedArticles.data}
                    options={chartTopRatedArticles.options}
                    className="chart-canvas"
                    id="chart-doughnut"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="bg-default mb-3">
              <CardHeader className="bg-default">
                <h5 className="text-white h3 mb-0">Top Rated Authors</h5>


              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Doughnut
                    data={chartTopRatedAuthors.data}
                    options={chartTopRatedAuthors.options}
                    className="chart-canvas"
                    id="chart-doughnut"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          
          {/* <Col>
            <Card className="bg-default mb-3">
              <CardHeader className="bg-default">
                <h5 className="text-white h3 mb-0">Total Spent</h5>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={chartTotalSpent.data}
                    options={chartTotalSpent.options}
                    className="chart-canvas"
                    id="chart-bar-stacked"
                  />
                </div>
              </CardBody>
            </Card>
          </Col> */}
          
        </Row>

      </Container>
    </>
  )
}

export default Analytics