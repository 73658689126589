import Header from 'components/Headers/Header';
import { api } from 'Interceptor';
import React, { useEffect, useState } from 'react';
import { Card, Container, Pagination, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { CardHeader, Spinner } from 'reactstrap';
import CreateCategoryModal from '../../components/Modal.js/UserModal'
import CreditsModal from 'components/Modal.js/CreditsModal';

const UserCredit = () => {
  const [category, setCategory] = useState([])
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  async function getData() {
    const { data: { Credits, totalPages } } = await api.headers().method('get', `/credits?page=${currentPage}&limit=10`)
    // console.log(Credits);
    setTotalPages(totalPages);
    
    Credits.map((cat, id) => {

      cat['s.no'] = id + 1
      cat['FullName'] = cat?.fullName;
      cat['Email'] = <a href={`mailto:${cat?.email}`}>{cat?.email}</a>
      cat['Role'] = cat?.role
      cat['IsBlocked'] = <span className={`badge ${cat?.isBlocked ? "badge-danger" : "badge-success"}`}>{cat?.isBlocked ? "Un-Active" : "Active"}</span>
      cat['credits'] = cat?.coins
      cat['update'] = <CreditsModal user={cat?.user} getData={getData} fullName={cat?.fullName} coins={cat?.coins} />
      return cat
    })
    setCategory(Credits)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getData()
  }, [currentPage]);
  const columns = [
    {
      dataField: 's.no',
      text: '#',
    },
    {
      dataField: 'FullName',
      text: 'Full Name',
    },
    {
      dataField: 'Email',
      text: 'Email',
    },
    {
      dataField: 'Role',
      text: 'Role',
    },
    {
      dataField: 'IsBlocked',
      text: 'Active',
    },
    {
      dataField: 'credits',
      text: 'Credits',
    },
    {
      dataField: 'update',
    },
   

  ]
  return <>
    <div>
    <Header />
      {
        category == false ? ( 
          <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
          <Spinner color="primary"/>
        </div> ): (
          <Container className="mt--7" fluid>
            <Row className="mt-5">
              <div className="col">
                <Card className="bg-default shadow ">
                  <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                    <h3 className="text-white mb-0">Credits Control</h3>
                  </CardHeader>
                  <BootstrapTable bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id" data={category} columns={columns}
                    noDataIndication='No Users Found'
                  />

                  <Pagination style={{margin: '1rem'}}>
                    <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                    <Pagination.Item active>{currentPage}</Pagination.Item>
                    <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                  </Pagination>

                  <p style={{ margin: '1rem', fontStyle: 'italic', color: 'white' }}>Total Pages: {totalPages}</p>
    
                </Card>
              </div>
            </Row>
          </Container> 
        )
      }
    </div>
    <CreateCategoryModal />
  </>
    ;
};

export default UserCredit;
