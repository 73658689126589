const initia_state = {}


export const toastReducer = (state = initia_state, action) => {
    switch (action.type) {
        case "CHANGE_TOASTER":
            return {
                state,
                ...action.payload
            }
        case "CLOSE_TOASTER":
            return {
                show: false
            }
        default:
            return {
                ...state,
                show: false,
            }
    }
}