import Header from 'components/Headers/Header';
import { api } from 'Interceptor';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CardHeader, Spinner, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Label } from 'reactstrap';
import { OPEN_MODAL } from 'Store/Actions/Modal/Action';
import moment from "moment";
import CreateCategoryModal from '../../components/Modal.js/UserModal'
import Placeholder from "../../assets/user.png";

const ViewAnalytics = () => {
  const [category, setCategory] = useState([])
  const dispatch = useDispatch()
  // const { modalReducer } = useSelector(state => state)
  const [prev_data, setPrevData] = useState([])
  const [novelTitle, setNovelTitle] = useState('')
  const [selectedDate, setSelectedDate] = useState('');

  // console.log(selectedDate);
  
  async function getData() {
    const { data: { actv_user, paid_views, unique_views } } = await api.headers().method('post', '/user-activities', {selectedDate: selectedDate})
    const temp_arr = []
    if (novelTitle == ''){
      Object.keys(actv_user).forEach(function(key, index) {
        var count = 0
        actv_user[key].forEach(() => {
          count+=1
        })
        
        const paidCount = paid_views[key] || 0;
        const uniqueCount = unique_views[key] || 0;

        temp_arr.push({'title': <p style={{cursor:"pointer"}} onClick={() => {setPrevData([]); setNovelTitle(key)}}>{key}</p>, 'total_views' : count, 'unique_views': uniqueCount, 'paid_views': paidCount}, )
      });
    } else {
      actv_user[novelTitle].forEach(function callback(value, index)  {
        // console.log(value.user.firstName+' '+value.user.lastName)
        temp_arr.push({
          'chapter': value.chapter?.title, 
          'userName': (value.user?.firstName == '' && value.user?.lastName == '') ? '-' : value.user?.firstName+' '+value.user?.lastName, 
          'email': value.user?.email,
          'views_date': moment(value.createdAt).format('DD-MMM-YYYY'),
        })
      })
    }
    
    setPrevData(temp_arr)
    // console.log(prev_data)
  }
  useEffect(() => {
    getData()
  }, [novelTitle, selectedDate]);

  let columns = [
    {
      dataField: 'title',
      text: 'Title',
    },
    {
      dataField: 'total_views',
      text: 'Total Views',
    },
    {
      dataField: 'unique_views',
      text: 'Unique Viewers',
    },
    {
      dataField: 'paid_views',
      text: 'Paid Views',
    },
  ]
  if (novelTitle != '') {
    columns = [
      {
        dataField: 'chapter',
        text: 'Chapter',
      },
      {
        dataField: 'userName',
        text: 'Name',
      },
      {
        dataField: 'email',
        text: 'Email',
      },
      {
        dataField: 'views_date',
        text: 'Views Date',
      },
    ]
  }
  
  return <>
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow ">
              <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                <h3 className="text-white mb-0">{novelTitle=='' ? 'Chapters Analytics' : novelTitle}</h3>

                {
                  novelTitle=='' ? 
                  ( <div style={{display: "flex", gap: "1rem", alignItems: "center"}}>
                      <label style={{color: "white"}}>Start from</label>
                      <input type='date' value={selectedDate} onChange={(e)=>setSelectedDate(e.target.value)}
                      style={{ padding: "0.5rem", borderRadius: "4px", border: "1px solid #ccc" }}
                      ></input>
                    </div>
                  ) 
                  : null
                }
                
                {
                  novelTitle!='' && 
                  <Button color="primary" onClick={() => {setPrevData([]); setNovelTitle('')}}>Back</Button>
                }
              </CardHeader>
              {
                prev_data == false ? ( 
                  <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
                  <Spinner color="primary"/>
                </div> ): (
              <BootstrapTable bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id" data={prev_data} columns={columns}
                noDataIndication='No Users Found'
                />
                )
              }
            </Card>
          </div>
        </Row>
      </Container>
    </div>
    <CreateCategoryModal />
  </>
    ;
};

export default ViewAnalytics;
