import { toastMessage } from 'Helper/Toaster';
import { api } from 'Interceptor';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap'
import error from "../../assets/error.jpg";
import loader from "../../assets/loader.gif";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { showNotification } from 'Helper/Functions';
import Header from 'components/Headers/Header';

const SingleChapter = () => {

  let { slug } = useParams();
  let { articleSlug } = useParams();

  const [loading, setLoading] = useState(true);
  const [chapter, setChapter] = useState();
  
  useEffect(() => {
    api.headers().method('get', `/article/${articleSlug}/chapter/${slug}`).then((response) => {
      setLoading(false);
      // Do manipulations
      const chapterData = response?.data?.chapter;
      chapterData.content = chapterData?.content.replaceAll("<p></p>",'');
      chapterData.content = chapterData?.content.replaceAll(/\\n/gi,'');
      chapterData.content = chapterData?.content.replaceAll(/\/“/gi,'“');
      // chapterData.content = chapterData?.content.replaceAll(/\“/\/gi,'“');
      setChapter(response?.data?.chapter)
    }).catch((er) => showNotification(er))
  }, [])
  return (
    <>
    <Header />
    <Container>
      {
        
        loading == true ?  <div className='loader'><img src={loader} /></div>   :
        <div className='container'>
          <Row className=' justify-content-center'>
            <div className='col-auto text-center'>
              <h1 className='title'>{chapter?.title}</h1>
            </div>

            <div className='col-lg-12'>
              <div className='content'
                dangerouslySetInnerHTML={{ __html: chapter?.content }}
                
              
              />
            </div>
          </Row>

        </div>
      }
    </Container>
   </>
  )
}

export default SingleChapter