import { toastMessage } from 'Helper/Toaster';
import { api } from 'Interceptor';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Form, Row } from 'react-bootstrap';
import GloabalModal from './GlobalModal';
import { useFormik, FormikProvider } from "formik";
import { Formik, Field } from 'formik';
import Userpic from "../../assets/user.png";
import { useDispatch } from 'react-redux'
import { CLOSE_MODAL } from '../../Store/Actions/Modal/Action'
import moment from "moment";
import * as Yup from 'yup';


const TagsModal = () => {
  const { modalReducer: { id, typeOfmodal, slug, data } } = useSelector(state => state);
  const dispatch = useDispatch()

  const [submitted, setSubmitted] = useState(false);
  const closeModal = () => {
    dispatch(CLOSE_MODAL({ isOpen: false }))
  }
  const validateobjects = Yup.object().shape({

    title: Yup
      .string().min(2, 'Minimum 2 characters required').max(50, 'Too Long!')
      .required('This field is required'),
    idTitle: Yup
      .string().min(2, 'Minimum 2 characters required').max(50, 'Too Long!')
      .required('This field is required'),

  });

  const formik = useFormik({
    initialValues: {
      title: "",
      idTitle: "",
      isVisible: true,

    },
    validationSchema: validateobjects,
    onSubmit: (values) => {

      if (typeOfmodal === 'update-tag') {
        api.headers().method('put', `/tag/update/${id}`, values).then((response) => {
          toastMessage.success("Tag Updated Successfully");
          closeModal();
          formik.resetForm({
            values: {
              title: "",
              idTitle: "",
            }
          });
        }).catch((error) => {
          toastMessage.failure(error?.response?.data?.message || "Tag couldn't be submitted!")
        });
      }

      if (typeOfmodal === 'create-tag') {
        api.headers().method("post", `/tag/create`, values).then((response) => {
          toastMessage.success("Tag Created Successfully");

          closeModal();
          formik.resetForm({
            values: {
              title: "",
              idTitle: "",
            }
          });
        }).catch((error) => {
          toastMessage.failure(error?.response?.data?.message || "Tag couldn't be submitted!")
        });
      }
    },
  });
  useEffect(() => {
    if (id) {
      if (typeOfmodal === "update-tag") {

        formik.values.title = data?.title;
        formik.values.idTitle = data?.idTitle;
      }
    }


  }, [id, typeOfmodal])
  const deleteTags = async () => {
    const del = await api.headers().method('delete', `/tag/delete/${id}`)
    toastMessage.success("Tag deleted successfully")
    closeModal()

  }

  if (typeOfmodal === "delete-tag") {
    return (
      <GloabalModal heading="Delete Tag" >
        <h4>Would you like to delete this Tag?</h4>
        <Button variant="primary" onClick={deleteTags}>Delete</Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }
  if (typeOfmodal === "create-tag") {
    return (
      <Formik value={formik}>

        <GloabalModal handleParentClose={closeModal} size="lg" heading='Create Tag' >
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="mb-3 col-lg-6">
                <label>Title</label>
                <Field className="form-control" type="text" placeholder="Title" name="title" onChange={formik.handleChange}
                  value={formik.values.title} onBlur={formik.handleBlur} />
                {formik.errors.title && formik.touched.title ? <div className='field-error'>{formik.errors.title}</div> : null}

              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Title(Bahasa)</label>
                <Field className="form-control" type="text" placeholder="Title" name="idTitle" onChange={formik.handleChange}
                  value={formik.values.idTitle} onBlur={formik.handleBlur} />
                {formik.errors.idTitle && formik.touched.idTitle ? <div className='field-error'>{formik.errors.idTitle}</div> : null}

              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <input type="radio" id="isVisible" name="isVisible" value={true} defaultChecked onChange={formik.handleChange} />
                <label for="Comic" className="ml-2">Visible</label>
                <input type="radio" id="isVisible" name="isVisible" value={false} onChange={formik.handleChange} className="ml-2" />
                <label for="Novel" className="ml-2">Hide</label>
              </Form.Group>


            </Row>


            <Button variant="primary" type="submit"
              isLoading={formik.isSubmitting}
              loadingText={"Loading"}
              // onClick={() => {formik.setSubmitting(true);formik.handleSubmit()}}
              >
              Submit
            </Button>
          </Form >
        </GloabalModal>
      </Formik>
    )
  }

  return (

    <>

      <Formik value={formik}>
        <GloabalModal heading={typeOfmodal === 'update-tag' ? "Update Tag" : "Create Tag"}>

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="mb-3 col-lg-6">
                <label>Title</label>
                <Field className="form-control" type="text" placeholder="Title" name="title" onChange={formik.handleChange}
                  value={formik.values.title} />
                  {formik.errors.title && formik.touched.title ? <div className='field-error'>{formik.errors.title}</div> : null}
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Title(Bahasa)</label>
                <Field className="form-control" type="text" placeholder="Title" name="idTitle" onChange={formik.handleChange}
                  value={formik.values.idTitle} />
                  {formik.errors.idTitle && formik.touched.idTitle ? <div className='field-error'>{formik.errors.idTitle}</div> : null}
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                {
                  data?.isVisible == true ? <input type="radio" id="isVisible" name="isVisible" value={true} onChange={formik.handleChange} defaultChecked /> : <input type="radio" id="isVisible" name="isVisible" value={true} onChange={formik.handleChange} />
                }

                <label for="Comic" className="ml-2">Visible</label>
                {
                  data?.isVisible == false ?
                    <input type="radio" id="isVisible" name="isVisible" value={false} onChange={formik.handleChange} className="ml-2" defaultChecked /> : <input type="radio" id="isVisible" name="isVisible" value={false} onChange={formik.handleChange} className="ml-2" />
                }
                <label for="Novel" className="ml-2">Hide</label>
              </Form.Group>


            </Row>


            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form >


        </GloabalModal >
      </Formik>



    </>
  )

}

export default TagsModal;
