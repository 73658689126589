import { toastMessage } from 'Helper/Toaster';
import { api } from 'Interceptor';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Form, Row } from 'react-bootstrap'
import GloabalModal from './GlobalModal'
import Userpic from "../../assets/user.png";
import { useDispatch } from 'react-redux'
import { CLOSE_MODAL } from '../../Store/Actions/Modal/Action'
import { useFormik, FormikProvider } from "formik";
import { Formik, Field } from 'formik';
import moment from "moment";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';





const animatedComponents = makeAnimated();

const ArticleModal = () => {

  const { modalReducer: { id, typeOfmodal, slug, data } } = useSelector(state => state);
  const dispatch = useDispatch()
  const closeModal = () => {
    dispatch(CLOSE_MODAL({ isOpen: false }))
  }
  const [submitted, setSubmitted] = useState(false);
  const [tagsoptions, setTagsOptons] = useState([])
  const [getTags, setTags] = useState([])
  const [genreoptions, setGenreOptons] = useState([])
  const [langoptions, setLangOptons] = useState([])
  const [getGenres, setGenres] = useState([])
  const [articleCreators, setCreators] = useState(null)
  const [selectedCreator, setSelectedCreator] = useState(null)
  const deleteContact = async () => {
    const del = await api.headers().method('delete', `/article/delete/${id}`)
    toastMessage.success("Article deleted successfully")
    closeModal()

  }
  const deleteComment = async (c_id) => {
    const del = await api.headers().method('delete', `/article/${id}/comment/delete/${c_id}`)
    toastMessage.success("Comment successfully")
    // closeModal()
  }

  const approveComment = async (c_id, index) => {
    let isActiveBefore = data.comments[index].isActive;
    const del = await api.headers().method('put', `/article/${id}/comment/approve/${c_id}`).then((res)=>  toastMessage.success(res?.data?.message))
   
    data.comments[index].isActive = !isActiveBefore;
    // closeModal()
  }

  const printStars = (rating) => {
    const starsArray = []
    for (let i = 1; i <= 5; i++) {
      if (rating >= i) {

        starsArray.push(<i class="fas fa-star" style={{ color: "#ef7226" }}></i>)
      }
      else if (rating < i) {
        starsArray.push(<i class="far fa-star" style={{ color: "#ef7226" }}></i>)
      }
    }
    return starsArray
  }
  const lang = [
    { value: "en", label: "English" },
    { value: "id", label: "Indonesian" },
  ]

  if (typeOfmodal === "view-chapter") {

    <Link to={`/novels/${id}`} />
  }
  const approveArticle = async () => {

    if (data.isActive == true) {
      data.isActive = false

    }
    else {
      data.isActive = true
    }
    const requestBody = { isActive: data.isActive }
    const app = await api.headers().method('put', `/article/approve/${id}`, requestBody)
    toastMessage.success("Novel approved successfully")
    closeModal()
  }
  const freeChapterformik = useFormik({
    initialValues: {
      _id:"",
      freeChapterAccess:""
    },

    onSubmit: (values) => {
      
      values._id = data?._id
      api.headers().method('put', `/settings/update/${values._id}`, values).then((res)=>{
        formik.setSubmitting(false);
        toastMessage.success("Settings Updated")
      }).catch((er)=> {
        
        showNotification(er)
      })
    },
  });


  const [category, setCategory] = useState({});

  const formik = useFormik({
    initialValues: {
      coverPicture: "",
      title: "",
      articleType: "",
      synopsis: "",
      slug: "",
      sortOrder: "",
      tags: "",
      genres: "",
      languages: "",
    },


    onSubmit: (values) => {

      values.tags = tagsoptions.map((x) => x.value)
      values.genres = genreoptions.map((x) => { return x.value })
      values.languages = langoptions.map((x) => x.value)
      const requestBody = new FormData();
      requestBody.append("json_data", JSON.stringify(values));
      requestBody.append("coverPicture", values.coverPicture);


      if (typeOfmodal === 'update-article') {
        api.headers().method('put', `/article/update/${id}`, requestBody).then((response) => {
          formik.setSubmitting(false);
          toastMessage.success("Novel Updated Successfully");
          closeModal();
        }).catch((error) => {
          toastMessage.failure(error?.response?.data?.message || "Novel couldn't be submitted!")
        });
      }

      if (typeOfmodal === 'create-genre') {
        api.headers().method("post", `/genre/create`, values).then((response) => {
          toastMessage.success("Genre Created Successfully");
          closeModal();
        }).catch((error) => {
          toastMessage.failure(error?.response?.data?.message || "Genre couldn't be submitted!")
        });
      }
    },
  });

  useEffect(() => {
    if (id) {
      if (typeOfmodal === "update-article") {

        api.headers().method('get', `/article/${slug}`).then(async (response) => {

          const responseData = response?.data?.article;

          if (!responseData) return;
          formik.values.title = responseData.title;
          formik.values.articleType = responseData.articleType;
          formik.values.synopsis = responseData.synopsis;
          formik.values.slug = responseData.slug;
          formik.values.sortOrder = responseData.sortOrder;
          const tempTags = responseData.tags;
          const tempGenres = responseData.genres;
          const tempLang = responseData.languages;
          const l = tempLang.map((x) => {
            return { value: x, label: x }
          })
          setLangOptons(l)

          api.headers().method('get', '/tags').then(async (response) => {

            const b = response?.data.tags.map((x) => {
              return { value: x?._id, label: x?.title }
            })

            setTags(b)
            const a = tempTags?.map((x) => {
              return { value: x?._id, label: x?.title }
            })
            setTagsOptons(a)
            const { data } = await api.headers().method('get', '/genres')
            const d = data?.genres?.map((x) => {
              return { value: x?._id, label: x?.title }
            })
            setGenres(d)

            const e = tempGenres?.map((x) => {
              return { value: x?._id, label: x?.title }
            })
            setGenreOptons(e)

          })

          const { data: { profiles } } = await api.headers().method('get', '/users')
          const creators = profiles?.map((x) => { return { value: x._id, label: x?.user?.fullName } })
          setSelectedCreator({ value: responseData.createdBy._id, label: responseData.createdBy?.user?.fullName });
          setCreators(creators);

        })

      }
    }


  }, [id, typeOfmodal])


  if (typeOfmodal === "delete-articles") {
    return (
      <GloabalModal heading="Delete Novel" >
        <h4>Would you like to delete this Novel?</h4>
        <Button variant="primary" onClick={deleteContact}>Delete</Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }




  if (typeOfmodal === "approve-article") {
    return (
      <GloabalModal handleParentClose={closeModal} heading={`${data?.isActive ? "Unpublish" : "Publish"} Novel`} >
        <h4>Would you like to {`${data?.isActive ? "Unpublish" : "Publish"} Novel`}?</h4>
        <Button variant="primary" onClick={approveArticle}>{`${data?.isActive ? "Unpublish" : "Publish"} Novel`}</Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }
  if (typeOfmodal === "report") {

    return (

      <GloabalModal heading="Reports">
        {data?.report.length ? data?.report.map((x, index) => {
          return (
            <Row>
              <div className='col-lg-6 col-sm-12 '>
                <h2>Category</h2>
              </div>
              <div className='col-lg-6 col-sm-12 '>
                <label>{x?.category}</label>
              </div>
              <div className='col-lg-6 col-sm-12 '>
                <h2>User</h2>
              </div>
              <div className='col-lg-6 col-sm-12 '>
                <label>{x?.user?.fullName}</label>
              </div>
              <div className='col-lg-6 col-sm-12 '>
                <h2>Reason</h2>
              </div>
              <div className='col-lg-6 col-sm-12 '>
                <label>{x?.reason}</label>
              </div>
              <div className='col-lg-6 col-sm-12 '>
                <h2>Date</h2>
              </div>
              <div className='col-lg-6 col-sm-12 '>
                <label>{moment(x?.createdAt).format("DD-MM-YYYY")}</label>
              </div>
              <div className='col-lg-6 col-sm-12 '>
                <h2>Active</h2>
              </div>
              <div className='col-lg-6 col-sm-12 '>
                {
                  x?.isActive == true ? <i class="fas fa-check"></i> : <i class="fas fa-times"></i>
                }


              </div>

            </Row>
          )
        }) : <Row>
          <div className='col-lg-12 col-sm-12 ml-9'>
            <label>No Reports </label>
          </div>
        </Row>
        }

      </GloabalModal >

    )

  }

  if (typeOfmodal === "comments") {

    return (

      <GloabalModal heading="Comments">
        {data?.comments.length ? data?.comments.map((x, index) => {
          return (
            <Row>
              <div className='col-lg-2 col-sm-12'>
                <img src={x?.user?.profilePicture || Userpic} className="talent_image " onError={({ currentTarget }) => (currentTarget.src = Userpic)} />
              </div>
              <div className='col-lg-9 mt-2'>
                <h2 htmlFor='full name'> {x?.user?.fullName} {" "} <span className='moment_date'>{moment(x.createdAt).fromNow()}</span></h2>
                <div>
                  {
                    printStars(x?.rating)
                  }
                  <span className={`badge ${!x.isActive ? "badge-danger" : "badge-success"}`}>{!x.isActive ? "Inactive" : "Active"}</span>
                </div>
                <label> {x.comment}</label>
              </div>
              <div className='col-lg-1 mt-2'>
                <i className={`${x.isActive ? 'fas' : 'far'} fa-check-circle`} onClick={() => { approveComment(x?._id, index) }} style={{ cursor: 'pointer' }}></i>
              </div>


            </Row>
          )
        }) : <Row>
          <div className='col-lg-12 col-sm-12 ml-9'>
            <label>No Comments </label>
          </div>
        </Row>
        }

      </GloabalModal >

    )

  }
  if (typeOfmodal === "view-chapters") {

    return (
      <GloabalModal heading={typeOfmodal === 'view-chapters'}>
       
          <Row>


            <div className='contact col-lg-4 col-sm-12 mt-3 '>
              <h4 htmlFor="name">Details </h4>

            </div>
            <div className='contact col-lg-8 col-sm-12 mt-3'>

              <label htmlFor="fees" className='message_bookings'>{data?.synopsis} </label>

            </div>

          </Row >
      </GloabalModal >
    )
  }
  if (typeOfmodal === "update-article") {
    return (

      <Formik value={formik}>
        <GloabalModal heading={typeOfmodal === 'update-article' ? "Update Novel" : "Create Novel"}>

          <Form onSubmit={formik.handleSubmit}>
            <Row>

              <Form.Group className="mb-3 col-lg-6">
                <img src={data?.coverPicture || Userpic} className="talent_image " onError={({ currentTarget }) => (currentTarget.src = Userpic)} />
                <label for="coverPicture" className='ml-2'>Select a file:</label>
                <input type="file" id="coverPicture" accept="image/*" name="coverPicture" className='mt-2' onChange={(e) => { formik.setFieldValue("coverPicture", e.currentTarget.files[0]) }} />

              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Title</label>
                <Field className="form-control" type="text" placeholder="Full Name" name="title" onChange={formik.handleChange} onBlur={formik.handleBlur}
                  value={formik.values.title} />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6">
                <label>Synopsis</label>
                <Field className="form-control" type="text" placeholder="Synopsis" name="synopsis" onChange={formik.handleChange}
                  onBlur={formik.handleBlur} value={formik.values.synopsis} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Novel Type</label>
                <br />
                <input type="radio" id="articleType" name="articleType" value="comic" checked={formik.values.articleType == 'comic'} onChange={formik.handleChange} />
                <label for="Comic" className="ml-2">Comic</label>
                <input type="radio" id="articleType" name="articleType" value="novel" checked={formik.values.articleType == 'novel'} onChange={formik.handleChange} className="ml-2" />
                <label for="Novel" className="ml-2">Novel</label>
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Slug</label>
                <Field className="form-control" type="text" placeholder="Slug" name="slug" onChange={formik.handleChange}
                  value={formik.values.slug} onBlur={formik.handleBlur}/>
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Sort Order</label>
                <Field className="form-control" type="text" placeholder="Sort Order" name="sortOrder" onChange={formik.handleChange}
                  value={formik.values.sortOrder} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Tags</label>
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={tagsoptions}
                  onChange={setTagsOptons}
                  isMulti
                  options={getTags}
                  name="tags"
                />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Genre</label>
                <Select

                  value={genreoptions}
                  onChange={(e) => { setGenreOptons([e]) }}

                  options={getGenres}
                  name="genres"
                />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Languages</label>
                <Select

                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={langoptions}
                  onChange={setLangOptons}
                  isMulti
                  options={lang}
                  name="languages"
                />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>CreatedBy</label>
                <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={selectedCreator}
                  onChange={setSelectedCreator}
                  options={articleCreators}
                  name="languages"
                />
              </Form.Group>

            </Row>


            <Button variant="primary" type="submit"
              isLoading={formik.isSubmitting}
              loadingText={"Loading"}
              // onClick={() => {formik.setSubmitting(true); formik.handleSubmit()}}
              >
              Submit
            </Button>
          </Form >


        </GloabalModal >
      </Formik>
    )

  }
  return (
    <>
      <GloabalModal heading={typeOfmodal === 'view-articles' ? "View Novel" : "Create Novel"}>
       
          <Row>

            <div className='contact col-lg-4 col-sm-12 mt-2'>
              <h4 htmlFor="name">Novel Picture</h4>
            </div>
            <div className='contact col-lg-8 col-sm-12'>

              <img src={data?.coverPicture || Userpic} className="talent_image " onError={({ currentTarget }) => (currentTarget.src = Userpic)} />
            </div>
            <div className='contact col-lg-4 col-sm-12 mt-3 '>
              <h4 htmlFor="name">Author </h4>

            </div>
            <div className='contact col-lg-8 col-sm-12 mt-3'>

              <label htmlFor="name">{data?.createdBy?.user?.fullName} </label>
              <a href={`mailto:${data?.createdBy?.user?.email}`}> {`(${data?.createdBy?.user?.email})`}</a>

            </div>


            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="title">Title </h4>

            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <a href={`${data?.title}`} >{data?.title}</a>
            </div>
            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="fees">Slug </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <a href={`${data?.slug}`} >{data?.slug}</a>
            </div>
            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="company">Novel Type </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="company">{data?.articleType} </label>
            </div>
            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="phone_number">Genres </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="phone_number">{data?.genres?.map((x) => x.title)?.join(', ')} </label>
            </div>
            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="phone_number">Likes </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="phone_number">{data?.likedBy} </label>
            </div>
            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="fees">Languages </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="fees">{data?.languages?.join(', ')} </label>
            </div>
            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="fees">Synopsis </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="fees" className='message_bookings'>{data?.synopsis} </label>
            </div>
            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="message">Rating </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="message" >{data?.rating} </label>
            </div>

            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="message">Tags </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="message" >{data?.tags?.map((x) => x.title)?.join(', ')} </label>
            </div>


            <div className='contact col-lg-4 col-sm-12 mt-2'>
              <h4>Created AT</h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>
              <label htmlFor="date">{moment(data?.createdAt).format("DD-MM-YYYY")} </label>

            </div>
          </Row >
      </GloabalModal >



    </>
  )

}

export default ArticleModal;
