import { toastMessage } from 'Helper/Toaster';
import { api } from 'Interceptor';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, FormFloating } from 'react-bootstrap';
import { Form, Row, Container } from 'react-bootstrap'
import Userpic from "../../assets/user.png";
import { useFormik } from "formik";
import { Formik, Field } from 'formik';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useHistory, useParams } from 'react-router-dom';
import Header from 'components/Headers/Header';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Form as FormBS } from "react-bootstrap";


const AddArticle = () => {

  const { slug } = useParams();
  const history = useHistory();

  const [isEditMode, setIsEditMode] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [articleData, setArticleData] = useState(null);
  const [selectedTags, setSelectedTags] = useState([])
  const [getTags, setTags] = useState([])
  const [selectedGenres, setSelectedGenres] = useState([])
  const [selectedLangs, setSelectedLangs] = useState([])
  const [getGenres, setGenres] = useState([])
  const [articleCreators, setCreators] = useState(null)
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [createdDate, setCreatedDate] = useState();

  const lang = [
    { value: "en", label: "English" },
    { value: "id", label: "Indonesian" },
  ]

  const conditions = [{
    value: "serializing", label: "Serializing"
  }, {
    value: "finished", label: "Finished"
  }]
  const validateobjects = Yup.object().shape({

    title: Yup.string().min(2, 'Minimum 2 characters required').max(50, 'Too Long!').required('This field is required'),
    synopsis: Yup.string().min(10, 'Minimum 10 characters required').required('This field is required!'),
    sortOrder: Yup.number().required('This field is required!'),

  });
  const formik = useFormik({
    initialValues: {
      coverPicture: "",
      title: "",
      articleType: "novel",
      synopsis: "",
      slug: "",
      sortOrder: "0",
      tags: "",
      genres: "",
      languages: "",
      createdBy: "",
      warningText: "",
      freeChapterAccess:"",
      createdAt:"",
      condition: null,
      ageRestriction: false,
    },

    validationSchema: validateobjects,
    onSubmit: (values) => {

      values.tags = selectedTags.map((x) => x.value)
      values.genres = selectedGenres.map((x) => { return x.value })
      values.languages = selectedLangs.map((x) => x.value)
      values.createdBy = selectedCreator?.value;
      values.condition = values.condition?.value;
      const requestBody = new FormData();
      requestBody.append("json_data", JSON.stringify(values));
      requestBody.append("coverPicture", values.coverPicture);

      let type, url, message;

      if (isEditMode) {
        type = 'put', url = `/article/update/${articleData._id}`, message = "Novel Updated Successfully";
      } else {
        type = 'post', url = `/article/create`, message = "Novel Created Successfully";
      }

      api.headers().method(type, url, requestBody).then((response) => {
        formik.setSubmitting(false);
        toastMessage.success(message);
        history.push('/admin/novels');
      }).catch((error) => {
        toastMessage.failure(error?.response?.data?.message || "Novel couldn't be submitted!")
      });
    },
  });

  // Get Article Data from API
  const getArticleData = (slug) => {
    api.headers().method('get', `/article/${slug}`).then(async (response) => {

      const responseData = response?.data?.article;

      if (!responseData) return;
      setArticleData(responseData);
      formik.values.title = responseData.title;
      formik.values.articleType = responseData.articleType;
      formik.values.synopsis = responseData.synopsis;
      formik.values.slug = responseData.slug;
      formik.values.warningText = responseData.warningText;
      formik.values.condition = conditions.find(x => x.value === responseData.condition);
      formik.values.sortOrder = responseData.sortOrder;
      formik.values.freeChapterAccess = responseData.freeChapterAccess;
      formik.values.ageRestriction = responseData.ageRestriction;
      setCreatedDate(responseData.createdAt)
      // formik.values.createdAt = responseData.createdAt;

      // Transform selectedGenresData into { value: "value", label: "title" } for React-Select
      const selectedGenresData = responseData.genres?.map((x) => ({ value: x?._id, label: x?.title }))
      
      setSelectedGenres(selectedGenresData);

      const selectedTagsData = responseData.tags?.map((x) => ({ value: x?._id, label: x?.title }))
      setSelectedTags(selectedTagsData);

      const selectedLangsData = responseData.languages?.map((x) => ({ value: x, label: lang.find(y => y.value == x).label }))
      setSelectedLangs(selectedLangsData);

      setSelectedCreator({ value: responseData.createdBy._id, label: responseData.createdBy?.user?.fullName });

    })
  }

  // Get Tags Data from API
  const getTagsData = () => {
    api.headers().method('get', '/tags').then(async ({ data }) => {
      const allTags = data.tags.map((x) => ({ value: x?._id, label: x?.title }));
      setTags(allTags)
    })
  }

  // Get Genre Data from API
  const getGenresData = () => {
    api.headers().method('get', '/genres').then(({ data }) => {
      const allGenres = data?.genres?.map((x) => ({ value: x?._id, label: x?.title }))
      setGenres(allGenres);
    })
  }

  // Get Genre Data from API
  const getUsersData = async () => {
    const { data: { profiles } } = await api.headers().method('get', '/users')
    const creators = profiles?.filter(x => x?.user?.role === "content-creator")?.map((x) => { return { value: x._id, label: x?.user?.fullName } })
    setCreators(creators);
  }

  useEffect(() => {
    if (slug) {
      setIsEditMode(true);
      getArticleData(slug);
    } else {
      setIsEditMode(false)
    }
    getTagsData();
    getGenresData();
    getUsersData();
  }, [])

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Formik value={formik}>
          <Form className='page--form' onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="mb-3 col-lg-6">
                <img src={articleData?.coverPicture || Userpic} className="talent_image " onError={({ currentTarget }) => (currentTarget.src = Userpic)} />
                <label for="coverPicture" className='ml-2'>Select a file:</label>
                <input type="file" id="coverPicture" accept="image/*" name="coverPicture" className='mt-2' onChange={(e) => { formik.setFieldValue("coverPicture", e.currentTarget.files[0]) }} />

              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Title</label>
                <Field className="form-control" type="text" placeholder="Title" name="title" onChange={formik.handleChange}
                  value={formik.values.title} onBlur={formik.onBlur} />
                {formik.errors.title && formik.touched.title ? <div className='field-error'>{formik.errors.title}</div> : null}
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6">
                <label>Synopsis</label>
                <textarea className="form-control" type="text" placeholder="Synopsis" name="synopsis" onChange={formik.handleChange}
                  value={formik.values.synopsis} onBlur={formik.onBlur} />
                {formik.errors.synopsis && formik.touched.synopsis ? <div className='field-error'>{formik.errors.synopsis}</div> : null}
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6">
                <label>Article Type</label>
                <br />
                <input type="radio" id="articleType" name="articleType" value="comic" checked={formik.values.articleType == 'comic'} onChange={formik.handleChange} />
                <label for="Comic" className="ml-2">Comic</label>
                <input type="radio" id="articleType" name="articleType" value="novel" checked={formik.values.articleType == 'novel'} onChange={formik.handleChange} className="ml-2" />
                <label for="Novel" className="ml-2">Novel</label>
                <br /><br />
                <label>Restricted Content</label>
                <input type="checkbox" id="ageRestriction" className="ml-3" value={formik.values.ageRestriction} checked={formik.values.ageRestriction} onChange={formik.handleChange} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Slug</label>
                <Field className="form-control" disabled={!isEditMode} type="text" placeholder="Slug" name="slug" onChange={formik.handleChange}
                  value={formik.values.slug} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Sort Order</label>
                <Field className="form-control" type="text" placeholder="Sort Order" name="sortOrder" onChange={formik.handleChange}
                  value={formik.values.sortOrder} onBlur={formik.onBlur} />
                {formik.errors.sortOrder && formik.touched.sortOrder ? <div className='field-error'>{formik.errors.sortOrder}</div> : null}
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Condition</label>
                <Select
                  value={formik.values.condition}
                  onChange={(e) => formik.setFieldValue('condition', e)}
                  options={conditions}
                  name="condition"
                />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Tags</label>
                <Select
                  closeMenuOnSelect={false}
                  value={selectedTags}
                  onChange={setSelectedTags}
                  isMulti
                  options={getTags}
                  name="tags"
                />
                {selectedTags.length > 0 ? "" : <div className='field-error'> This field is required </div>}
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Genre</label>
                <Select
                   closeMenuOnSelect={false}
                   value={selectedGenres }
                   onChange={setSelectedGenres}
                   isMulti
                   options={getGenres}
                   name="tags"
                />
                {selectedGenres.length > 0 ? "" : <div className='field-error'> This field is required </div>}
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Language</label>
                <Select
                  value={selectedLangs}
                  onChange={(e) => { setSelectedLangs([e]) }}
                  options={lang}
                  name="languages"
                />
                {selectedLangs.length > 0 ? "" : <div className='field-error'> This field is required </div>}
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Created By</label>
                <Select
                  value={selectedCreator}
                  onChange={setSelectedCreator}
                  options={articleCreators}
                  name="languages"
                  // isDisabled={!isEditMode}
                />
                {selectedCreator !== null ? "" : <div className='field-error'> This field is required </div>}
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Warning Text</label>
                <textarea className="form-control" type="text" placeholder="Warning Text" name="warningText" onChange={formik.handleChange}
                  value={formik.values.warningText} onBlur={formik.onBlur} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Created At  </label>
                {
                  createdDate && (
                    <h2>{moment(createdDate).format('MMMM Do YYYY, h:mm:ss a')}</h2>
                  )
                }
                

                <DatePicker className="form-control"  minDate={new Date()} showTimeSelect selected={formik.values.createdAt} onChange={(date) => formik.setFieldValue('createdAt', date)} dateFormat="MMMM d, yyyy h:mm aa" />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Free Chapter Access</label>
                <input className="form-control" type="text" placeholder="Free Chapter Access" name="freeChapterAccess" onChange={formik.handleChange}
                  value={formik.values.freeChapterAccess} onBlur={formik.onBlur} />
              </Form.Group>
             

            </Row>

            {
              selectedGenres.length && selectedLangs.length && selectedTags.length > 0 ? <Button variant="primary" type="submit"
                isLoading={formik.isSubmitting}
                loadingText={"Loading"}
                // onClick={() => {formik.setSubmitting(true);formik.handleSubmit()}}
                >
                Submit
              </Button> : <Button variant="primary" type="submit" disabled>
                Submit
              </Button>
            }

          </Form >
        </Formik>
      </Container>
    </>
  );
};

export default AddArticle;
