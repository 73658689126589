import Header from 'components/Headers/Header';
import { api } from 'Interceptor';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { OPEN_MODAL } from 'Store/Actions/Modal/Action';
import CreateCategoryModal from '../../components/Modal.js/GenresModal'
import { Link } from 'react-router-dom';
import Userpic from "../../assets/user.png";

const ViewGenres = () => {
  const [category, setCategory] = useState([])
  const dispatch = useDispatch()
  const { modalReducer } = useSelector(state => state)

  async function getData() {
    const { data: { genres } } = await api.headers().method('get', '/genres')

    genres.map((cat, id) => {

      cat['s.no'] = <img style={{ marginRight: 15 }} src={ cat?.genreImage || Userpic} className="talent_image " onError={({ currentTarget }) => (currentTarget.src = Userpic)} />
      cat['Title'] = cat?.title
      cat['BahasaTitle'] = cat?.idTitle
      cat['Slug'] = <Link to={`/genres/${cat?.slug}`}>{cat?.slug}</Link>
      cat['actions'] = <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          href="#pablo"
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'update-genre', id: cat?._id, slug: cat?.slug, data: cat }))}>Update Genre</DropdownItem>
          <DropdownItem
          className="bg-translucent-danger text-white"
          hidden={JSON.parse(localStorage.getItem('superadmin-session'))?.user?.role !== "superadmin" ? true : false}
          onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: cat?._id, typeOfmodal: 'delete-genre' }))}>
          Delete Genre
          </DropdownItem>

        </DropdownMenu>
      </UncontrolledDropdown>
      return cat
    })
    setCategory(genres)
  }
  useEffect(() => {
    getData()
  }, [modalReducer]);
  const columns = [
    {
      dataField: 's.no',
      text: '#',
    },
    {
      dataField: 'Title',
      text: 'Title',
    },
    {
      dataField: 'BahasaTitle',
      text: 'Title(Bahasa)',
    },

    {
      dataField: 'Slug',
      text: 'Slug',
    },
    
    {
      dataField: 'actions',
      text: 'Actions',
    },
    

  ]
  return <>
    <div>
      <Header />
      <Container className="mt--7" fluid>
      
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow ">
              <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                <h3 className="text-white mb-0">Genres</h3>
                <Button color="primary" onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'create-genre' }))}>Create Genre</Button>
             
              </CardHeader>
              <BootstrapTable bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id" data={category} columns={columns}
                noDataIndication='No Genres Found'
              />

            </Card>
          </div>
        </Row>
      </Container>
    </div>
    <CreateCategoryModal />
  </>
    ;
};

export default ViewGenres;
