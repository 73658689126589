import React from "react";
import { Card, CardHeader } from "reactstrap";
import { useEffect } from "react";
import Header from "components/Headers/Header";
import {customDateFormat} from "../../Helper/Functions";
import { Button, Col, Container, Row } from "react-bootstrap";
import { api } from "Interceptor/index.js";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useSelector } from "react-redux";
import moment from "moment";


const RevenueSystem = () => {

  const [category, setCategory] = useState([])
  const [filter, setFilter] = useState({ type: true, day: "month" })
  const [paid, setPaid] = useState({ type: true, money: true })
  const { modalReducer } = useSelector(state => state)
  async function getData() {
    const { data: { articleViewsData } } = await api.headers().method('post', '/revenue', { filterBy: filter.day, isPaidView: paid.money })
    articleViewsData.map((cat, id) => {
      cat['s.no'] = id + 1
      cat['TotalViews'] = <><strong style={{fontWeight: 700, color: "#ffbe00",}}>{cat.totalViews}</strong> Views</>
      cat['Date'] = filter.type ? customDateFormat(cat?.date) : moment(cat?.date).format('DD-MMM-YYYY');
      cat['CoinsSpent'] = <><strong style={{fontWeight: 700, color: "#ffbe00",}}>{cat.coinsSpent}</strong> Chapters</>
      cat['TimeSpent'] = cat.timeSpent ? <><strong style={{fontWeight: 700, color: "#ffbe00",}}>{(cat.timeSpent/60000).toFixed(1)}</strong> Minutes</> : "-";
      return cat
    })
    setCategory(articleViewsData)
  }

  const ExportButton = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <Button color="primary" onClick={ handleClick }>Export to CSV</Button>
    );
  };

  useEffect(() => {
    getData()
  }, [modalReducer, filter, paid]);
  const columns = [
    {
      dataField: 's.no',
      text: '#',
    },
    {
      dataField: 'TotalViews',
      text: 'Total Views',
      csvFormatter: (cell, row, rowIndex) => `${row.totalViews}`
    },
    {
      dataField: 'Date',
      text: 'Date',
    }
  ]
  if (paid.money) {
    const timeSpentIndex = columns.findIndex(x => x.dataField === 'TimeSpent');
    if (timeSpentIndex !== -1) columns.splice(timeSpentIndex, 1);
    columns.push({
      dataField: 'CoinsSpent',
      text: 'Chapters Unlocked',
      csvFormatter: (cell, row, rowIndex) => `${row.coinsSpent}`
    });
  } else {
    const coinsSpentIndex = columns.findIndex(x => x.dataField === 'CoinsSpent');
    if (coinsSpentIndex !== -1) columns.splice(coinsSpentIndex, 1);

    columns.push({
      dataField: 'TimeSpent',
      text: 'Time Spent',
      csvFormatter: (cell, row, rowIndex) => `${(row.timeSpent/60000).toFixed(1)} Minutes`
    });
  }
  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col lg={6}>
            <ul className="justify-content-start mb-4 nav nav-pills">
              <li className="mr-2 mr-md-0 nav-item">
                <a href="#" className={`py-2 px-3 nav-link ${filter.type == true ? '' : 'active'}`} onClick={() => setFilter({ day: 'month', type: true }) }>
                  Month
                </a>
              </li>
              <li className="mr-2 mr-md-0 nav-item">
                <a href="#" className={`py-2 px-3 nav-link ${filter.type == false ? '' : 'active'}`} onClick={() => setFilter({ day: 'day', type: false }) }>
                  Day
                </a>
              </li>
            </ul>
          </Col>
          <Col lg={6} style={{textAlign: 'right'}}>
            <ul className="justify-content-start mb-4 justify-content-end nav nav-pills">
              <li className="mr-2 mr-md-0 nav-item">
                <a href="#" className={`py-2 px-3 nav-link ${paid.type == true ? '' : 'active'}`} onClick={() => setPaid({ type: true, money: true }) }>
                  Paid
                </a>
              </li>
              <li className="mr-2 mr-md-0 nav-item">
                <a href="#" className={`py-2 px-3 nav-link ${paid.type == false ? '' : 'active'}`} onClick={() => setPaid({ type: false, money: false }) }>
                  Free
                </a>
              </li>
            </ul>
          </Col>
          <div className="col">
            <Card className="bg-default shadow ">
              <ToolkitProvider
                keyField="id"
                data={category} 
                columns={columns}
                exportCSV
              >
                {
                  props => (
                    <>
                    <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                      <h3 className="text-white mb-0">Revenue System</h3>
                      <ExportButton { ...props.csvProps }></ExportButton>
                    </CardHeader>
                    <BootstrapTable { ...props.baseProps } bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id"
                      noDataIndication='No records found'
                    />
                    </>
                  )
                }
              </ToolkitProvider>
            </Card>
          </div>
        </Row>

      </Container>
    </>
  )
}

export default RevenueSystem