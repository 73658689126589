import Header from 'components/Headers/Header';
import { api } from 'Interceptor';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { OPEN_MODAL } from 'Store/Actions/Modal/Action';
import moment from "moment";
import CreateCategoryModal from '../../components/Modal.js/NotificationsModal'
import { toastMessage } from 'Helper/Toaster';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const ViewNotifications = () => {
  const [category, setCategory] = useState([])
  const dispatch = useDispatch();
  const { modalReducer } = useSelector(state => state);

  async function getData() {
    const { data: { notifications } } = await api.headers().method('get', '/notifications')

    notifications.map((cat, id) => {

      cat['s.no'] = id + 1
      cat['Title'] = cat?.title?.length > 45 ? <span title={cat?.title}>{`${cat?.title?.substring(0,45)}...`}</span> : cat?.title;
      cat['Type'] = cat?.notificationType;
      cat['Content'] = cat?.content?.length > 45 ? <span title={cat?.content}>{`${cat?.content?.substring(0,45)}...`}</span> : cat?.content;
      cat['Date'] =  moment(cat?.createdAt).format('LLL');
      cat['actions'] = <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          href="#pablo"
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
        <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'view-notify', id: cat?._id, data: cat }))}>View Notification Details</DropdownItem>
          {/* <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'update-tag', id: cat?._id, slug: cat?.slug, data: cat }))}>Update Notify</DropdownItem> */}
          <DropdownItem
          className="bg-translucent-danger text-white"
          hidden={JSON.parse(localStorage.getItem('superadmin-session'))?.user?.role !== "superadmin" ? true : false}
          onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: cat?._id, typeOfmodal: 'delete-notify' }))}>
          Delete Notification
          </DropdownItem>

        </DropdownMenu>
      </UncontrolledDropdown>
      return cat
    })
    setCategory(notifications)
  }
  useEffect(() => {
    getData()
  }, [modalReducer]);
  const columns = [
    {
      dataField: 's.no',
      text: '#',
    },
    {
      dataField: 'Title',
      text: 'Title',
      classes: 'iyg-col-narrow',
    },
    {
      dataField: 'Type',
      text: 'Notification Type',
    },
    
    {
      dataField: 'Content',
      text: 'Description',
      classes: 'iyg-col-narrow',
    },
    
    {
      dataField: 'Date',
      text: 'Date',
    },
    
    {
      dataField: 'actions',
      text: 'Actions',
    },
    

  ]
  return <>
    <div>
      <Header />
      <Container className="mt--7" fluid>
      
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow ">
              <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                <h3 className="text-white mb-0">Notifications</h3>
                <Button color="primary"
                hidden={JSON.parse(localStorage.getItem('superadmin-session'))?.user?.role !== "superadmin" ? true : false} 
                onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'create-notify' }))}>
                Create Notification
                </Button>
             
              </CardHeader>
              <BootstrapTable bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id" data={category} columns={columns}
                noDataIndication='No Notifications Found'
              />

            </Card>
          </div>
        </Row>
      </Container>
    </div>
    <CreateCategoryModal />
  </>
    ;
};

export default ViewNotifications;
