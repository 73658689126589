import Header from 'components/Headers/Header';
import { api } from 'Interceptor';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { OPEN_MODAL } from 'Store/Actions/Modal/Action';
import moment from "moment";
import CreateCategoryModal from '../../components/Modal.js/NotificationsModal'
import { toastMessage } from 'Helper/Toaster';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const AdminActivity = () => {
  const [category, setCategory] = useState([])
  const dispatch = useDispatch();
  const { modalReducer } = useSelector(state => state);

  async function getData() {
    const { data: { activities } } = await api.headers().method('get', '/admin-activity')
    console.log(activities);

    activities.map((cat, id) => {

      cat['s.no'] = id + 1
      cat['Admin'] = cat?.fullName ? cat.fullName : cat?.firtsName
      cat['ActivityType'] = cat?.activityType;
      cat['Target'] = cat?.activity?.targetTitle?.length > 45 ? <span title={cat?.activity.targetTitle}>{`${cat?.activity?.targetTitle?.substring(0,45)}...`}</span> : cat?.activity?.targetTitle;
      cat['Date'] =  moment(cat?.createdAt).format('LLL');
      
      return cat
    })
    setCategory(activities)
  }
  useEffect(() => {
    getData()
  }, [modalReducer]);
  const columns = [
    {
      dataField: 's.no',
      text: '#',
    },
    {
      dataField: 'Admin',
      text: 'Admin',
      classes: 'iyg-col-narrow',
    },
    {
      dataField: 'ActivityType',
      text: 'Activity Type',
    },
    
    {
      dataField: 'Target',
      text: 'Target',
      classes: 'iyg-col-narrow',
    },
    
    {
      dataField: 'Date',
      text: 'Date',
    },
  

  ]
  return <>
    <div>
      <Header />
      <Container className="mt--7" fluid>
      
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow ">
              <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                <h3 className="text-white mb-0">activities</h3>
                {/* <Button color="primary"
                hidden={JSON.parse(localStorage.getItem('superadmin-session'))?.user?.role !== "superadmin" ? true : false} 
                onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'create-notify' }))}>
                Create Notification
                </Button> */}
             
              </CardHeader>
              <BootstrapTable
                bordered={false}
                headerClasses='thead-dark'
                bodyClasses='text-light'
                wrapperClasses="table-responsive table-dark"
                keyField="id"
                data={category}
                columns={columns}
                noDataIndication='No activities Found'
              />

            </Card>
          </div>
        </Row>
      </Container>
    </div>
    <CreateCategoryModal />
  </>
    ;
};

export default AdminActivity;
