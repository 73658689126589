import { store } from "Store/Store";

class ToasterClass {
    constructor() {

    }
    closeToaster() {
        store.dispatch({
            type: 'CLOSE_TOASTER'
        })
    }
    success(message) {
        store.dispatch({
            type: 'CHANGE_TOASTER',
            payload: {
                show: true,
                className: "bg-success",
                message: message,
                iconClassName: 'fas fa-check',
                isDisabled: false

            }
        })
        setTimeout(() => {
            this.closeToaster()
        }, 3000);

    }
    info(message) {
        store.dispatch({
            type: 'CHANGE_TOASTER',
            payload: {
                show: true,
                className: "bg-info",
                message: message,
                iconClassName: 'fas fa-info',
                isDisabled: false

            }
        })
        setTimeout(() => {
            this.closeToaster()
        }, 3000);

    }
    warning(message) {
        store.dispatch({
            type: 'CHANGE_TOASTER',
            payload: {
                show: true,
                className: "bg-warning",
                message: message,
                iconClassName: 'fas fa-check',
                isDisabled: false

            }
        })
        setTimeout(() => {
            this.closeToaster()
        }, 3000);

    }
    loading() {
        store.dispatch({
            type: 'CHANGE_TOASTER',
            payload: {
                show: true,
                className: "bg-light",
                message: "Wait While the data is being fetched",
                iconClassName: 'fas fa-spinner fa-pulse',
                isDisabled: true
            }
        })
    }
    failure(message) {
        store.dispatch({
            type: 'CHANGE_TOASTER',
            payload: {
                show: true,
                className: "bg-danger",
                message: message,
                iconClassName: 'fas fa-times',
                isDisabled: false

            }
        })
        setTimeout(() => {
            this.closeToaster()
        }, 3000);
    }

}


export const toastMessage = new ToasterClass();