import Header from 'components/Headers/Header';
import { api } from 'Interceptor';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Pagination, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { OPEN_MODAL } from 'Store/Actions/Modal/Action';
import CreateCategoryModal from '../../components/Modal.js/UserModal'
import Placeholder from "../../assets/user.png";

const ViewUsers = () => {
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState([])
  const dispatch = useDispatch()
  const { modalReducer } = useSelector(state => state)
  async function getData() {
    const { data: { users, totalPages } } = await api.headers().method('get', `/users?page=${currentPage}&limit=10`)
    
    setUsers(users);
    setTotalPages(totalPages);

    users.map((cat, id) => {

      cat['s.no'] = id + 1
      cat['FullName'] = cat?.fullName;
      cat['Email'] = <a href={`mailto:${cat?.email}`}>{cat?.email}</a>
      cat['Role'] = cat?.role
      cat['IsBlocked'] = <span className={`badge ${cat?.isBlocked ? "badge-danger" : "badge-success"}`}>{cat?.isBlocked ? "Un-Active" : "Active"}</span>
      cat['Profilepic']= <img src={cat?.profilePicture || Placeholder } className="talent_image " onError={({ currentTarget }) => (currentTarget.src = Placeholder)} />
      cat['actions'] = <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          href="#pablo"
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem 
          hidden={JSON.parse(localStorage.getItem('superadmin-session'))?.user?.role !== "superadmin" ? true : false}
          onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'edit-user', id: cat?._id, slug: cat?.slug, data: cat }))}>
          Edit User
          </DropdownItem>
          <DropdownItem 
          style={{ color: "red" }}
          hidden={JSON.parse(localStorage.getItem('superadmin-session'))?.user?.role !== "superadmin" ? true : false}
          onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: cat?._id, data: cat, typeOfmodal: 'approve-user' }))}>
          {cat.isBlocked ? "Un-Block User" : "Block User"}
          </DropdownItem>
          {/* <DropdownItem className="bg-translucent-danger text-white" onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: cat?._id, typeOfmodal: 'delete-users' }))}>Delete User</DropdownItem> */}

        </DropdownMenu>
      </UncontrolledDropdown>
      return cat
    })
    setCategory(users)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getData()
  }, [modalReducer, currentPage]);

  const columns = [
    {
      dataField: 's.no',
      text: '#',
    },
    {
      dataField: 'FullName',
      text: 'Full Name',
    },
    {
      dataField: 'Email',
      text: 'Email',
    },
    {
      dataField: 'Role',
      text: 'Role',
    },
    {
      dataField: 'IsBlocked',
      text: 'Active',
    },
    {
      dataField: 'Profilepic',
      text: 'Profile Picture',
    },
    {
      dataField: 'actions',
      text: 'Actions',
    },

  ]
  return <>
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow ">
              <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                <h3 className="text-white mb-0">Users</h3>
                <Button color="primary" 
                hidden={JSON.parse(localStorage.getItem('superadmin-session'))?.user?.role !== "superadmin" ? true : false} 
                onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'create-user' }))}>
                Create User
                </Button>
             
              </CardHeader>
              <BootstrapTable bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id" data={category} columns={columns}
                noDataIndication='No Users Found'
              />

            <Pagination style={{margin: '1rem'}}>
              <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
              <Pagination.Item active>{currentPage}</Pagination.Item>
              <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            </Pagination>

            <p style={{ margin: '1rem', fontStyle: 'italic', color: 'white' }}>Total Pages: {totalPages}</p>

            </Card>
          </div>
        </Row>
      </Container>
    </div>
    <CreateCategoryModal />
  </>
    ;
};

export default ViewUsers;
