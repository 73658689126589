import { toastMessage } from 'Helper/Toaster';
import { api } from 'Interceptor';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Form, Row } from 'react-bootstrap';
import GloabalModal from './GlobalModal';
import { useFormik, FormikProvider } from "formik";
import { Formik, Field } from 'formik';
import Userpic from "../../assets/user.png";
import { useDispatch } from 'react-redux'
import { CLOSE_MODAL } from '../../Store/Actions/Modal/Action'
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';

const NotificationsModal = () => {
  const { modalReducer: { id, typeOfmodal, slug, data } } = useSelector(state => state);
  const dispatch = useDispatch()

  const [submitted, setSubmitted] = useState(false);
  const closeModal = () => {
    dispatch(CLOSE_MODAL({ isOpen: false }))
  }
  const validateobjects = Yup.object().shape({

    title: Yup
      .string().min(2, 'Minimum 2 characters required').max(50, 'Too Long!')
      .required('This field is required'),
    content: Yup
      .string().min(2, 'Minimum 2 characters required').max(50, 'Too Long!')
      .required('This field is required'),
    notificationType: Yup
      .string()
      .required('This field is required'),

  });

  const formik = useFormik({
    initialValues: {
      title: "",
      notificationType: "",
      content: "",
      scheduledAt: "",

    },
    validationSchema: validateobjects,

    onSubmit: (values) => {

      if (typeOfmodal === 'create-notify') {
        api.headers().method("post", `/notification/create`, values).then((response) => {
          toastMessage.success("Notification Created Successfully");
          formik.resetForm({
            values:{
              title: "",
              notificationType: "",
              content: "",
            }
          });
          closeModal();
        }).catch((error) => {
          toastMessage.failure(error?.response?.data?.message || "Tag couldn't be submitted!")
        });
      }
    },
  });

  const deleteTags = async () => {
    const del = await api.headers().method('delete', `/notification/delete/${id}`)
    toastMessage.success("Notification deleted successfully")
    closeModal()

  }

  const [category, setCategory] = useState({});
  const [selectDate, setDate] = useState(
    new Date(moment().subtract(13, "years"))
  );







  if (typeOfmodal === "delete-notify") {
    return (
      <GloabalModal heading="Delete Notifcation" >
        <h4>Would you like to delete this Notification?</h4>
        <Button variant="primary" onClick={deleteTags}>Delete</Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }
  if (typeOfmodal === "create-notify") {
    return (
      <Formik value={formik}>

        <GloabalModal handleParentClose={closeModal} size="lg" heading='Create Notifications' >
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="mb-3 col-lg-6">
                <label>Title</label>
                <Field className="form-control" type="text" placeholder="Title" name="title" onChange={formik.handleChange}
                  value={formik.values.title} onBlur={formik.handleBlur} />
                {formik.errors.title && formik.touched.title ? <div className='field-error'>{formik.errors.title}</div> : null}
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Notification Type</label>
                <select class="form-control mb-3 " aria-label="Default select example" name="notificationType" onChange={formik.handleChange}
                  value={formik.values.notificationType} onBlur={formik.handleBlur}
                >
                  <option value="">Select Type</option>
                  <option value="notice">Notice</option>
                  <option value="announcement">Announcement</option>
                  <option value="app-notification">App Notification</option>
                </select>
                {formik.errors.notificationType && formik.touched.notificationType ? <div className='field-error'>{formik.errors.notificationType}</div> : null}

              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Content</label>

                <Field className="form-control" type="text" placeholder="Content" name="content" onChange={formik.handleChange}
                  value={formik.values.content} onBlur={formik.handleBlur} />
                {formik.errors.content && formik.touched.content ? <div className='field-error'>{formik.errors.content}</div> : null}

                {/* <Field className="form-control" type="text" placeholder="title" name="content" onChange={formik.handleChange}
                  value={formik.values.content} onBlur={formik.handleBlur} />
                <label>Schedule</label>
                <DatePicker minDate={new Date()} showTimeSelect selected={formik.values.scheduledAt} onChange={(date) => formik.setFieldValue('scheduledAt', date)} dateFormat="MMMM d, yyyy h:mm aa" /> */}
              </Form.Group>

            </Row>


            <Button variant="primary" type="submit"
              isLoading={formik.isSubmitting}
              loadingText={"Loading"}
              // onClick={() => {formik.setSubmitting(true);formik.handleSubmit()}}
              >
              Submit
            </Button>
          </Form >
        </GloabalModal>
      </Formik>
    )
  }

  return (

    <>

      <GloabalModal heading={typeOfmodal === 'view-notify' ? "View Notifications" : "Create Notifications"}>
        
          <Row>

            <div className='contact col-lg-4 col-sm-12 mt-2'>
              <h4 htmlFor="name">Title</h4>
            </div>

            <div className='contact col-lg-8 col-sm-12 mt-3'>

              <label htmlFor="name">{data?.title} </label>

            </div>


            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="fees">Type </h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>
              <label htmlFor="name">{data?.notificationType} </label>

            </div>
            <div className='contact col-lg-4 col-sm-12 mt-2'>

              <h4 htmlFor="company">Content</h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>

              <label htmlFor="company">{data?.content} </label>
            </div>


            <div className='contact col-lg-4 col-sm-12 mt-2'>
              <h4>Created AT</h4>
            </div>
            <div className='contact col-lg-8 col-sm-12 mt-2'>
              <label htmlFor="date">{moment(data?.createdAt).format('LLL')}</label>

            </div>
          </Row >
      </GloabalModal >

    </>
  )

}

export default NotificationsModal;
