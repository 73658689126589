export const axiosBaseURL = process.env.React_App_baseURL;

export const propertiesProps = [
  {
    name: "region",
    value: "Region",
  },
  {
    name: "project_type",
    value: "Project Type",
  },
  {
    value: "Tenure",
    name: "tenure",
  },
  {
    value: "Unit Size",
    name: "unit_size",
  },
  {
    value: "Unit Type",
    name: "unit_type",
  },
  {
    value: "No Of Unit",
    name: "units",
  },
  {
    value: "Type Of Unit",
    name: "units_by_type",
  },
  {
    value: "Address",
    name: "address",
  },
  {
    value: "Architect",
    name: "architect",
  },
  {
    value: "No Of Bed Rooms",
    name: "bedrooms",
  },
  {
    value: "District",
    name: "district",
  },
  {
    value: "Estimate Scs",
    name: "estimate_scs",
  },
  {
    value: "Estimate Top",
    name: "estimate_top",
  },
  {
    value: "Land Size",
    name: "land_size",
  },
  {
    value: "Launch Date",
    name: "launch_date",
  },
  {
    value: "Max Price",
    name: "max_price",
  },
  {
    value: "Min Price",
    name: "min_price",
  },
  {
    value: "Priority",
    name: "priority",
  },
];
