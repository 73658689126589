import { toastMessage } from 'Helper/Toaster';
import { api } from 'Interceptor';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Form, Row } from 'react-bootstrap'
import GloabalModal from './GlobalModal'
import Userpic from "../../assets/user.png";
import { useDispatch } from 'react-redux'
import { CLOSE_MODAL } from '../../Store/Actions/Modal/Action'
import { useFormik  } from "formik";
import { Formik, Field } from 'formik';
import makeAnimated from 'react-select/animated';
import * as Yup from 'yup';

const animatedComponents = makeAnimated();

const UserModal = () => {
  const [submitted, setSubmitted] = useState(false);
  const { modalReducer: { id, typeOfmodal, slug, data } } = useSelector(state => state);
  const dispatch = useDispatch()
  const closeModal = () => {
   
    dispatch(CLOSE_MODAL({ isOpen: false }))
  }
 
  const deleteContact = async () => {
    const del = await api.headers().method('delete', `/article/delete/${id}`)
    toastMessage.success("Article deleted successfully")
    closeModal()

  }
  
  
  const approveUser = async () => {

    if (data.isBlocked == true) {
      data.isBlocked = false

    }
    else {
      data.isBlocked = true
    }
    const requestBody = { isBlocked: data.isBlocked }
    const app = await api.headers().method('put', `/user/${id}/activation`, requestBody)
    closeModal()
  }



  const validateobjects = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('This field is required!'),
      password: Yup
      .string().min(2, 'Minimum 8 characters required').max(50, 'Too Long!')
      .required('This field is required'),
    fullName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('This field is required!'),
    role: Yup.string().required('This field is required!'),
  });

  const formik = useFormik({
    initialValues: {
      fullName: '',
      password:'',
      role:'',
      email:'',
    },
    validationSchema: typeOfmodal === 'create-user' ? validateobjects : undefined,
    onSubmit: (values) => {
      if (typeOfmodal === 'create-user') {
        api.headers().method("post", `/user/create`, values).then((response) => {

          toastMessage.success("User Created Successfully");
          formik.resetForm({
            values:{
              fullName: "",
              password: "",
              email: "",
            }
          });
          closeModal();
        }).catch((error) => {
          toastMessage.failure(error?.response?.data?.message || "User couldn't be submitted!")
        });
      }
    },
  });
  
  const editUserformik = useFormik({
    initialValues: {
      firstName: '',
      lastName:'',
      middleName:'',
      coins:'',
      bio:'',
    },
    onSubmit: (values) => {

      if (typeOfmodal === 'edit-user') {
        api.headers().method("put", `/user/update`, values).then((response) => {
          console.log("response", response.data);
          toastMessage.success("User Edit Successfully");
          // formik.resetForm({
          //   values:{
          //     fullName: "",
          //     password: "",
          //     email: "",
          //   }
          // });
          closeModal();
        }).catch((error) => {
          toastMessage.failure(error?.response?.data?.message || "User couldn't be submitted!")
        });
      }
    },
  });


  useEffect(() => {
    if (id) {
      if (typeOfmodal === "edit-user") {

        api.headers().method('get', `/user/${id}`).then(async (response) => {
          const responseData = response?.data?.user;
          const responseDataProfile = response?.data?.profile;
          if (!responseData) return;
          editUserformik.values.profile_id = responseDataProfile._id;
          editUserformik.values.user_id = responseData._id;
          editUserformik.values.email = responseData.email;
          editUserformik.values.firstName = responseData.firstName;
          editUserformik.values.middleName = responseData.middleName;
          editUserformik.values.lastName = responseData.lastName;
          editUserformik.values.bio = responseDataProfile.bio;
          // editUserformik.values.coins = responseDataProfile.coins;

        })

      }
    }


  }, [id, typeOfmodal])


  if (typeOfmodal === "delete-articles") {
    return (
      <GloabalModal heading="Delete Articles" >
        <h4>Would you like to delete this Article?</h4>
        <Button variant="primary" onClick={deleteContact}>Delete</Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }




  if (typeOfmodal === "approve-user") {
    return (
      <GloabalModal handleParentClose={closeModal} heading={`${data?.isBlocked ? "Un-Block" : "Block"} User`} >
        <h4>Would you like to {`${data?.isBlocked ? "Un-Block" : "Block"} User`}?</h4>
        <Button variant="primary" onClick={approveUser}>{`${data?.isBlocked ? "Un-Block" : "Block"} User`}</Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }
  if (typeOfmodal === "create-user") {
    return (
      <Formik value={formik}>

        <GloabalModal handleParentClose={closeModal} size="lg" heading='Create User' >
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="mb-3 col-lg-6">
                <label>Email</label>
                <Field className="form-control" type="email" placeholder="Enter Email" name="email" onChange={formik.handleChange}
                  value={formik.values.email} onBlur={formik.onBlur} />
                  {formik.errors.email && formik.touched.email ? <div className='field-error'>{formik.errors.email}</div> : null}
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Password</label>
                <Field className="form-control" type="password" placeholder="Enter Password" name="password" onChange={formik.handleChange}
                  value={formik.values.password} onBlur={formik.onBlur}/>
                  {formik.errors.password && formik.touched.password ? <div className='field-error'>{formik.errors.password}</div> : null}
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Full Name</label>
                <Field className="form-control" type="text" placeholder="Enter Full Name" name="fullName" onChange={formik.handleChange}
                  value={formik.values.fullName} onBlur={formik.onBlur}/>
                  {formik.errors.fullName && formik.touched.fullName ? <div className='field-error'>{formik.errors.fullName}</div> : null}
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Role</label>
                <select class="form-control mb-3 " aria-label="Default select example" name="role" onChange={formik.handleChange}
                  value={formik.values.role} onBlur={formik.handleBlur}
                >
                  <option value="">Select Type</option>
                  {/* <option value="admin">Admin</option> */}
                  <option value="superadmin">Super Admin</option>
                  <option value="editor">Editor</option>
                  <option value="finance">Finance</option>
                  <option value="consumer">Consumer</option>
                  <option value="content-creator">Content Creator</option>
                </select>
                {formik.errors.role && formik.touched.role ? <div className='field-error'>{formik.errors.role}</div> : null}
              </Form.Group>
            </Row>


            <Button variant="primary" type="submit"
              isLoading={formik.isSubmitting}
              loadingText={"Loading"}
              onClick={() => formik.setSubmitting(true)}>
              Submit
            </Button>
          </Form >
        </GloabalModal>
      </Formik>
    )
  }

  if (typeOfmodal === "edit-user") {
    return (

      <Formik value={editUserformik}>
        <GloabalModal heading="Edit User">

          <Form onSubmit={editUserformik.handleSubmit}>
            <Row>

              <Form.Group className="mb-3 col-lg-6" style={{ display: "none" }}>
                <label>Profile ID</label>
                <Field className="form-control" type="text" placeholder="Profile ID" name="profile_id" onChange={editUserformik.handleChange}
                  value={editUserformik.values.profile_id} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6" style={{ display: "none" }}>
                <label>User ID</label>
                <Field className="form-control" type="text" placeholder="User ID" name="user_id" onChange={editUserformik.handleChange}
                  value={editUserformik.values.user_id} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6" style={{ display: "none" }}>
                <label>Email</label>
                <Field className="form-control" type="text" placeholder="Email" name="email" onChange={editUserformik.handleChange}
                  value={editUserformik.values.email} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>First Name</label>
                <Field className="form-control" type="text" placeholder="Full Name" name="firstName" onChange={editUserformik.handleChange}
                  value={editUserformik.values.firstName} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Middle Name</label>
                <Field className="form-control" type="text" placeholder="Last Name" name="middleName" onChange={editUserformik.handleChange}
                  value={editUserformik.values.middleName} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Last Name</label>
                <Field className="form-control" type="text" placeholder="Last Name" name="lastName" onChange={editUserformik.handleChange}
                  value={editUserformik.values.lastName} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Bio</label>
                <Field className="form-control" type="text" placeholder="Bio" name="bio" onChange={editUserformik.handleChange}
                  value={editUserformik.values.bio} />
              </Form.Group>

              {/* <Form.Group className="mb-3 col-lg-6">
                <label>Coins</label>
                <Field className="form-control" type="number" placeholder="Coins" name="coins" onChange={editUserformik.handleChange}
                  value={editUserformik.values.coins} />
              </Form.Group> */}
              

            </Row>


            <Button variant="primary" type="submit"
              isLoading={editUserformik.isSubmitting}
              loadingText={"Loading"}
              onClick={() => {editUserformik.setSubmitting(true);editUserformik.handleSubmit()}}>
              Submit
            </Button>
          </Form >


        </GloabalModal >
      </Formik>
    )

  }
 
  return (
    <>




    </>
  )

}

export default UserModal;
