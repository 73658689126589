import { toastMessage } from 'Helper/Toaster';
import { api } from 'Interceptor';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Form, Row } from 'react-bootstrap'
import GloabalModal from './GlobalModal'
import Userpic from "../../assets/user.png";
import { useFormik, FormikProvider } from "formik";
import { Formik, Field } from 'formik';
import { useDispatch } from 'react-redux'
import { CLOSE_MODAL } from '../../Store/Actions/Modal/Action'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';
import moment from "moment";
const ChaptersModal = () => {
  const [startDate, setStartDate] = useState(new Date());
  const { modalReducer: { id, typeOfmodal, slug, data } } = useSelector(state => state);
  const dispatch = useDispatch()
  const closeModal = () => {
    dispatch(CLOSE_MODAL({ isOpen: false }))
  }
  const [reason, setReason] = useState(false)
  const [commentIsActive, setCommentIsActive] = useState();
  const [schedule, setSchedule] = useState(false);
  const validateobjects = Yup.object().shape({
    status: Yup.string().required('This field is required'),
  });
  const deleteContact = async () => {
    const del = await api.headers().method('delete', `/chapter/delete/${id}`)
    toastMessage.success("Chapter deleted successfully")
    closeModal()

  }
  const deleteComment = async (c_id) => {
    
    const del = await api.headers().method('delete', `/chapter/${id}/comment/delete/${c_id}`)
    toastMessage.success("Chapter deleted successfully")
    closeModal()
  }
  const approveComment = async (c_id, index) => {
    const requestBody = {
      isActive: commentIsActive
    }
    let isActiveBefore = data.comments[index].isActive;
    const del = await api.headers().method('put', `/chapter/${id}/comment/approve/${c_id}`, requestBody).then((res)=> toastMessage.success(res?.data?.message))
    
    data.comments[index].isActive = !isActiveBefore;
    // closeModal()
  }
  // const approveComment = async (c_id) => {
  //   const requestBody = {
  //     isActive: commentIsActive
  //   }
  //   const approve = await api.headers().method('put', `/chapter/${id}/comment/approve/${c_id}`, requestBody)
  //   toastMessage.success("Appro successfully")
  //   closeModal()
  // }



  const [category, setCategory] = useState({});

  const formik = useFormik({
    initialValues: {
      title: "",
      slug: "",
      sortOrder: "",
      description: "",
      coverPicture: "",

    },

    onSubmit: (values) => {

      const requestBody = new FormData();
      requestBody.append("json_data", JSON.stringify(values));
      requestBody.append("coverPicture", values.coverPicture);


      if (typeOfmodal === 'update-chapter') {
        api.headers().method('put', `/chapter/update/${id}`, requestBody).then((response) => {
          formik.setSubmitting(false);
          toastMessage.success("chapter Updated Successfully");
          closeModal();
        }).catch((error) => {
          toastMessage.failure(error?.response?.data?.message || "Chapter couldn't be submitted!")
        });
      }

      if (typeOfmodal === 'create-chapter') {
        api.headers().method("post", `/chapter/create`, values).then((response) => {
          toastMessage.success("chapter Created Successfully");
          closeModal();
        }).catch((error) => {
          toastMessage.failure(error?.response?.data?.message || "Genre couldn't be submitted!")
        });
      }
    },
  });
  // const chapterFormik = useFormik({
  //   initialValues: {
  //     isActive: false,
  //     reason: "",

  //   },
  //   onSubmit: (values) => {
  //     values.isActive = values.isActive == "true" ? true : false;

  //     if (typeOfmodal === 'approve-chapter') {
  //       api.headers().method('put', `/chapter/submit-review/${id}`, values).then((response) => {
  //         formik.setSubmitting(false);
  //         toastMessage.success("chapter Approve Successfully");
  //         closeModal();
  //       }).catch((error) => {
  //         toastMessage.failure(error?.response?.data?.message || "Chapter couldn't be submitted!")
  //       });
  //     }

  //   },
  // });
  const approveChapter = async () => {

    if (data.isActive == true) {
      data.isActive = false

    }
    else {
      data.isActive = true
    }
    const requestBody = { isActive: data.isActive }
    const app = await api.headers().method('put', `/chapter/approve/${id}`, requestBody)
    toastMessage.success("Article approved successfully")
    closeModal()
  }
  const scheduleFormik = useFormik({
    initialValues: {
      chapters: [],
      scheduleDate: ""
    },
    onSubmit: (values) => {
      values.chapters = data,
        values.scheduleDate = startDate;

      api.headers().method('post', '/chapters/schedule', values).then((response) => {
        formik.setSubmitting(false);
        toastMessage.success("selected chapters approve successfully");
        closeModal();
      }).catch((error) => {
        toastMessage.failure(error?.response?.data?.message || "Chapter couldn't be submitted!")
      });

    },
  });
  const statusFormik = useFormik({
    initialValues: {
      status: "",
      scheduledDate: "",
      chapters: "",
    },
    validationSchema: validateobjects,
    onSubmit: (values) => {
      values.chapters = data;

      api.headers().method('post', `/chapters/status/change`, values).then((response) => {
        formik.setSubmitting(false);
        // toastMessage.success("Status approve successfully");
        closeModal();
        statusFormik.resetForm({
          values: {
            status: "",
            scheduledDate: "",
            chapters: "",
          }
        });
      }).catch((error) => {
        toastMessage.failure(error?.response?.data?.message || "Chapters couldn't be submitted!")
      });

    },
  });
  const singleStatusFormik = useFormik({
    initialValues: {
      status: "",
      scheduledAt: "",

    },
    validationSchema: validateobjects,
    onSubmit: (values) => {

      api.headers().method('put', `/chapter/${id}/update/status`, values).then((response) => {
        formik.setSubmitting(false);
        // toastMessage.success("Status approve successfully");
        closeModal();
        singleStatusFormik.resetForm({
          values: {
            status: "",
            scheduledAt: "",
            chapters: "",
          }
        });
      }).catch((error) => {
        toastMessage.failure(error?.response?.data?.message || "Chapters couldn't be submitted!")
      });

    },
  });


  useEffect(() => {
    if (id) {
      if (typeOfmodal === "update-chapter") {
        api.headers().method('get', `/chapter/${slug}`).then(async (response) => {
          const responseData = response?.data?.chapter;
          if (!responseData) return;
          formik.values.title = responseData.title;
          formik.values.description = responseData.description;
          formik.values.slug = responseData.slug;
          formik.values.sortOrder = responseData.sortOrder;
        })
      }
    }
    if (typeOfmodal === "approve-single-status") {
      statusFormik.setFieldValue('status', data.status);
      if (data.scheduledAt) statusFormik.setFieldValue('scheduledAt', new Date(data.scheduledAt));
    }
  }, [id, typeOfmodal])
  useEffect(async () => {
    if (typeOfmodal === 'view-chapters') {
      const { data: { content_category } } = api.method('get', `/chapters`)
      setCategory(content_category)
    }
  }, [id]);

  if (typeOfmodal === "delete-chapter") {
    return (
      <GloabalModal heading="Delete Chapter" >
        <h4>Would you like to delete this Chapter?</h4>
        <Button variant="primary" onClick={deleteContact}>Delete</Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }
  if (typeOfmodal === "update-chapter") {
    return (

      <Formik value={formik}>
        <GloabalModal heading={typeOfmodal === 'update-chapter' ? "Update Chapter" : "Create Chapter"}>

          <Form onSubmit={formik.handleSubmit}>
            <Row>

              <Form.Group className="mb-3 col-lg-6">
                <img src={data?.coverPicture || Userpic} className="talent_image " onError={({ currentTarget }) => (currentTarget.src = Userpic)} />
                <label for="coverPicture" className='ml-2'>Select a file:</label>
                <input type="file" id="coverPicture" accept="image/*" name="coverPicture" className='mt-2' onChange={(e) => { formik.setFieldValue("coverPicture", e.currentTarget.files[0]) }} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Title</label>
                <Field className="form-control" type="text" placeholder="Full Name" name="title" onChange={formik.handleChange}
                  value={formik.values.title} />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-6">
                <label>Slug</label>
                <Field className="form-control" type="text" placeholder="Slug" name="slug" onChange={formik.handleChange}
                  value={formik.values.slug} />
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Sort Order</label>
                <Field className="form-control" type="text" placeholder="Sort Order" name="sortOrder" onChange={formik.handleChange}
                  value={formik.values.sortOrder} />
              </Form.Group>

            </Row>


            <Button variant="primary" type="submit"
              isLoading={formik.isSubmitting}
              loadingText={"Loading"}
              onClick={() => {formik.setSubmitting(true);formik.handleSubmit()}}>
              Submit
            </Button>
          </Form >


        </GloabalModal >
      </Formik>
    )

  }
  if (typeOfmodal === "comments") {
    return (
      <GloabalModal heading="Comments">
        {data?.comments.length ? data?.comments.map((x, index) => {
          return (
            <Row>
              <div className='col-lg-2 col-sm-12'>

                <img src={x.user?.profilePicture || Userpic} className="talent_image " onError={({ currentTarget }) => (currentTarget.src = Userpic)} />
              </div>
              <div className='col-lg-8'>
                <h2 htmlFor='full name'> {x.user?.fullName}
                  <span className={`chapter-badge badge ${!x?.isActive ? "badge-danger" : "badge-success"}`}>{!x?.isActive ? "InActive" : "Active"}</span></h2>

              </div>
              <div className='col-lg-2 '>
                {
                  x?.isActive == true ? <i class="fas fa-check-circle" onClick={() => {
                    approveComment(x._id, index)
                    setCommentIsActive(false)
                  }} style={{ cursor: "pointer" }}></i> : <i class="far fa-check-circle" onClick={() => {
                    approveComment(x._id, index)
                    setCommentIsActive(true)
                  }} style={{ cursor: "pointer" }}></i>
                }
                <i class="fa fa-trash ml-2" aria-hidden="true" onClick={() => { deleteComment(x._id) }} style={{ cursor: 'pointer' }} />
              </div>
              <div className='col-lg-12 chapter-comments-text'>
                <h3 className='chapter-comments-text'> {x.text}</h3>
                <span >{moment(x?.createdAt).fromNow()}</span>
              </div>


            </Row>
          )
        }) : <Row>
          <div className='col-lg-12 col-sm-12 ml-9'>
            <label>No Comments </label>
          </div>
        </Row>
        }

      </GloabalModal >

    )

  }
  // if (typeOfmodal === "approve-chapter") {

  //   return (
  //     <GloabalModal handleParentClose={closeModal} heading={`${data.isActive ? "Unpublish" : "Publish"} Chapter`} >

  //       <Button variant="primary" type="submit"
  //         isLoading={chapterFormik.isSubmitting}
  //         loadingText={"Loading"}
  //         onClick={() => chapterFormik.handleSubmit()}>
  //         Submit
  //       </Button>
  //       <Button variant="light" onClick={closeModal}>Close</Button>
  //     </GloabalModal>
  //   )
  if (typeOfmodal === "approve-status") {

    return (
      <GloabalModal handleParentClose={closeModal} heading={"Change Status"} >
        <Row>
          <Form.Group className="mb-3 col-lg-6">
            <label>Status</label>
            <select class="form-control mb-3 " name="status" onChange={statusFormik.handleChange} value={statusFormik.values.status} onBlur={statusFormik.handleBlur} >
              <option value={null}>Select Type</option>
              <option value="draft">Draft</option>
              <option value="pending-review">Pending Review</option>
              <option value="scheduled">Scheduled</option>
              <option value="published">Published</option>
            </select>
            {statusFormik.errors.status && statusFormik.touched.status ? <div className='field-error'>{statusFormik.errors.status}</div> : null}
          </Form.Group>

          {statusFormik.values.status === "scheduled" && (
            <Form.Group className="mb-3 col-lg-6">
              <label>Scheduled at</label>
              <DatePicker className="form-control" minDate={new Date()} showTimeSelect selected={statusFormik.values.scheduledDate} onChange={(date) => statusFormik.setFieldValue('scheduledDate', date)} dateFormat="MMMM d, yyyy h:mm aa" />
            </Form.Group>
          )}
        </Row>

        <Button variant="primary" type="submit"
          isLoading={true}
          loadingText={"Loading"}
          onClick={() => statusFormik.handleSubmit()}>
          Submit
        </Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }
  if (typeOfmodal === "approve-single-status") {

    return (
      <GloabalModal handleParentClose={closeModal} heading={"Change Status"} >
        <Row>
          <Form.Group className="mb-3 col-lg-6">
            <label>Status</label>
            <select class="form-control mb-3 " name="status" onChange={singleStatusFormik.handleChange} value={singleStatusFormik.values.status} onBlur={singleStatusFormik.handleBlur} >
              <option value={null} >Select Type</option>
              <option value="draft">Draft</option>
              <option value="pending-review">Pending Review</option>
              <option value="scheduled">Scheduled</option>
              <option value="published">Published</option>
            </select>
            {singleStatusFormik.errors.status && singleStatusFormik.touched.status ? <div className='field-error'>{singleStatusFormik.errors.status}</div> : null}
          </Form.Group>
          {singleStatusFormik.values.status === "scheduled" && (
            <Form.Group className="mb-3 col-lg-6">
              <label>Scheduled at</label>
              <DatePicker className="form-control" minDate={new Date()} showTimeSelect selected={singleStatusFormik.values.scheduledAt} onChange={(date) => singleStatusFormik.setFieldValue('scheduledAt', date)} dateFormat="MMMM d, yyyy h:mm aa" />
            </Form.Group>
          )}
        </Row>

        <Button variant="primary" type="submit"
          isLoading={true}
          loadingText={"Loading"}
          onClick={() => statusFormik.handleSubmit()}>
          Submit
        </Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }

  if (typeOfmodal === "schedule") {

    return (

      <GloabalModal handleParentClose={closeModal} heading={"Schedule"} >
        <label for="Comic" className="ml-2">Date Picker</label>
        <DatePicker minDate={new Date()} showTimeSelect selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="MMMM d, yyyy h:mm aa" />
        <br />
        <br />
        <br />
        <Button variant="primary" type="submit"
          isLoading={scheduleFormik.isSubmitting}
          loadingText={"Loading"}
          onClick={() => scheduleFormik.handleSubmit()}>
          Schedule
        </Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }

  return (

    <>

      <GloabalModal heading={typeOfmodal === 'view-chapter' ? "View Chapters" : "Create Contacts"}>

        <Row>


          <div className='contact col-lg-4 col-sm-12 mt-3 '>
            <h4 htmlFor="name">Title </h4>

          </div>
          <div className='contact col-lg-8 col-sm-12 mt-3'>

            <label htmlFor="title">{data?.title} </label>

          </div>
          <div className='contact col-lg-4 col-sm-12 mt-3 '>
            <h4 htmlFor="name">Comments</h4>

          </div>
          <div className='contact col-lg-8 col-sm-12 mt-3'>

            <label htmlFor="title">{data?.comments?.length} </label>

          </div>



        </Row >
      </GloabalModal >



    </>
  )

}

export default ChaptersModal;
