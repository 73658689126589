import Header from 'components/Headers/Header';
import { api } from 'Interceptor';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap';
import { OPEN_MODAL } from 'Store/Actions/Modal/Action';
import CreateCategoryModal from '../../components/Modal.js/ChaptersModal'
import { toastMessage } from 'Helper/Toaster';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import SingleChapter from './SingleChapter';

const ViewChapters = () => {
  const { SearchBar } = Search;
  const { id } = useParams();
  const history = useHistory();
  const [category, setCategory] = useState([])
  const dispatch = useDispatch()
  const { modalReducer } = useSelector(state => state)
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [checkChapters, setCheckChapters] = useState([]);
  const [selected, setSelected] = useState([])
  
  let url = 'chapters';
  useEffect(() => {
    if (id) url = `/chapters/${id}`;
  }, [id, modalReducer])
  
  

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r._id);
    if (isSelect) {
      setCheckChapters(ids);
    } else {
      setCheckChapters([]);
    }
  }
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setCheckChapters((prev) => [...prev , row._id]);
    } else {
      checkChapters.splice(checkChapters.indexOf(row?._id), 1);
      setCheckChapters( [...checkChapters]);
      
    }
  }

  async function getData() {
    const { data: { chapters } } = await api.headers().method('get', url)
    let color = ""
    chapters.map((cat, id) => {
      if (cat?.status == "draft") color = "bg-secondary-custom"
      else if (cat?.status == "pending-review") color = "bg-warning"
      else if (cat?.status == "rejected") color = "bg-danger"
      else if (cat?.status == "scheduled") color = "bg-yellow"
      else color = "bg-success"

      // cat['s.no'] = <input
      //   type="checkbox"
      //   value={isSubscribed}
      //   onChange={(e) => handleCheck(e, cat)}
      //   id="subscribe"
      //   name="subscribe"
      // />;
      cat['title'] = cat?.title;
      cat['id'] = id + 1;
      cat['articleDataType'] = <>{cat?.article?.slug}
        <i class="fas fa-clipboard ml-2" style={{ cursor: "pointer" }} onClick={() => {
          navigator.clipboard.writeText(cat?.slug)
          toastMessage.info("Copied Successfully")
        }} /></>
      cat['Comments'] = <Link onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'comments', id: cat?._id, data: cat }))}> {cat.comments?.length}</Link>;
      cat.is_Active = <><span className={`badge ${color} text-dark`}>{cat?.status}</span>{cat?.scheduledAt ? <p><small>{moment(cat?.scheduledAt).format('MMMM DD, YYYY HH:mm')}</small></p> : ""}</>
      cat['actions'] = <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          href="#pablo"
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'view-chapter', id: cat?._id, slug: cat?.slug, data: cat }))}>View Chapter Details</DropdownItem>
          <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'update-chapter', id: cat?._id, slug: cat?.slug, data: cat }))}>Update Chapter</DropdownItem>
          <DropdownItem onClick={() => history.push(`/admin/novel/${cat?.article?.slug}/chapter/${cat?.slug}`)}>Read Chapter</DropdownItem>
          <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: cat?._id, data: cat, typeOfmodal: 'approve-status' }))}>Change Status</DropdownItem>
          <DropdownItem
          className="bg-translucent-danger text-white"
          hidden={JSON.parse(localStorage.getItem('superadmin-session'))?.user?.role !== "superadmin" ? true : false}
          onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: cat?._id, typeOfmodal: 'delete-chapter' }))}>
          Delete chapter
          </DropdownItem>

        </DropdownMenu>
      </UncontrolledDropdown>
      return cat
    })
    setCategory(chapters)
  }
  useEffect(() => {
    getData()
  }, [modalReducer]);
  const columns = [
    {
      dataField: 'id',
      text: 'Chapters ID'
    },
    {
      dataField: 'title',
      classes: 'iyg-col-narrow',
      text: 'Title',
      width: 100,
    },
    {
      dataField: 'articleDataType',
      classes: 'iyg-col-narrow',
      text: 'Article',
      width: 100,
    },
    {
      dataField: 'Comments',
      text: 'Comments',
    },
    {
      dataField: 'is_Active',
      text: 'Status',
    },
    {
      dataField: 'actions',
      text: 'Actions',
    },


  ]
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll

  };

  return <>
    <div>
      <Header />
      {
        category == false ? ( 
          <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
          <Spinner color="primary"/>
        </div> ): (
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow ">
              <ToolkitProvider
                keyField="id"
                data={category}
                columns={columns}
                search
              >
                {
                  props => (
                    <div>


                      <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                        <h3 className="text-white mb-0">Chapters</h3>
                        <div>
                          <SearchBar {...props.searchProps} />
                          {
                            checkChapters.length > 0 ? <Button color="primary" className='ml-2' onClick={() => {

                              dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'approve-status', data: checkChapters }))
                            }}>Change Status</Button> : <Button color="primary" className='ml-2' disabled>Change Status</Button>
                          }

                        </div>
                      </CardHeader>


                      <BootstrapTable bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id" data={category} columns={columns}
                        noDataIndication='No Chapters Found' selectRow={selectRow}
                        {...props.baseProps}
                      />
                    </div>
                  )
                }
              </ToolkitProvider>


            </Card>
          </div>
        </Row>
      </Container>
       )
      }
    </div>
    <CreateCategoryModal />
  </>
    ;
};

export default ViewChapters;
