import Header from 'components/Headers/Header';
import { api } from 'Interceptor';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { OPEN_MODAL } from 'Store/Actions/Modal/Action';
import CreateCategoryModal from '../../components/Modal.js/ArticleModal'
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { toastMessage } from 'Helper/Toaster';

const ViewArticles = () => {
  const { SearchBar } = Search;
  const [category, setCategory] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()
  const { modalReducer } = useSelector(state => state)
  
  async function getData() {
    const { data: { articles } } = await api.headers().method('get', '/articles')
    articles.map((cat, id) => {
      cat['likedBy'] = cat.likedBy.length
      cat['s.no'] = id + 1
      cat.fullName = cat.createdBy?.user?.fullName;
      cat['Comments'] = <Link onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'comments', id: cat?._id, data: cat }))}> {cat.comments?.length}</Link>;
      cat['Reports'] = <Link onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'report', id: cat?._id, data: cat }))}> {cat.report?.length}</Link>;
      cat['Genre'] = cat?.genres[0]?.title;
      cat['ageRestriction'] = <span className={`badge ${!cat.ageRestriction ? "badge-success" : "badge-danger"}`}>{!cat.ageRestriction ? "No" : "Yes"}</span>;
      cat['Slug'] = <>{cat?.slug}
      <i class="fas fa-clipboard ml-2" style={{ cursor: "pointer" }} onClick={() =>{navigator.clipboard.writeText(cat?.slug)
      toastMessage.info("Copied Successfully")
      }} /></>
      cat.is_Active = <span className={`badge ${!cat.isActive ? "badge-danger" : "badge-success"}`}>{!cat.isActive ? "Not Published" : "Published"}</span>
      cat['actions'] = <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          href="#pablo"
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, typeOfmodal: 'view-articles', id: cat?._id, data: cat }))}>View Novel Details</DropdownItem>
          <DropdownItem onClick={() => history.push(`/admin/article/edit/${cat?.slug}`)}>Update Novel</DropdownItem>
          <DropdownItem onClick={() => history.push(`/admin/article/${cat?.slug}/chapters`)}>Write Chapters</DropdownItem>
          <Link to={`/admin/novels/${cat?._id}`}><DropdownItem >View Chapters</DropdownItem></Link>
          <DropdownItem onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: cat?._id, data: cat, typeOfmodal: 'approve-article' }))}>{cat.isActive ? "Unpublish Novel" : "Publish Novel"}</DropdownItem>
          <DropdownItem
          className="bg-translucent-danger text-white"
          hidden={JSON.parse(localStorage.getItem('superadmin-session'))?.user?.role !== "superadmin" ? true : false}
          onClick={() => dispatch(OPEN_MODAL({ isOpen: true, id: cat?._id, typeOfmodal: 'delete-articles' }))}>
          Delete Novel
          </DropdownItem>

        </DropdownMenu>
      </UncontrolledDropdown>
      return cat
    })
    setCategory(articles)
  }
  useEffect(() => {
    getData()
  }, [modalReducer]);
  const columns = [
    {
      dataField: 'title',
      text: 'Title',

    },
    {
      dataField: 'Slug',
      text: 'Slug',
      classes: 'iyg-col-narrow',
    },
    {
      dataField: 'Genre',
      text: 'Genre',
    },
    {
      dataField: 'fullName',
      text: 'Full Name',
    },
    {
      dataField: 'ageRestriction',
      text: 'Restricted',
    },
    {
      dataField: 'likedBy',
      text: 'Likes',
    },
    {
      dataField: 'Reports',
      text: 'Reports',
    },
    {
      dataField: 'Comments',
      text: 'Comments',
    },
    {
      dataField: 'is_Active',
      text: 'Status',
    },
    {
      dataField: 'actions',
      text: 'Actions',
    },

  ]
  return <>
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <div className="col">

            <Card className="bg-default shadow ">

              <ToolkitProvider
                keyField="id"
                data={category}
                columns={columns}
                search
              >
                {
                  props => (
                    <div>
                      <CardHeader className="bg-transparent border-0 d-flex justify-content-between flex-wrap-reverse align-items-center">
                        <h3 className="text-white mb-0">Novels</h3>
                        <div>

                          <SearchBar {...props.searchProps} />
                          <Button color="primary" className='ml-2' onClick={() => history.push('/admin/novel/create')}>Create Novels</Button>
                        </div>
                      </CardHeader>


                      <BootstrapTable bordered={false} headerClasses='thead-dark' bodyClasses='text-light' wrapperClasses="table-responsive table-dark" keyField="id" data={category} columns={columns}
                        noDataIndication='No Novels Found'
                        {...props.baseProps}
                      />
                    </div>
                  )
                }
              </ToolkitProvider>

            </Card>
          </div>
        </Row>
      </Container>
    </div>
    <CreateCategoryModal />
  </>
    ;
};

export default ViewArticles;
