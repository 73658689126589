import { toastMessage } from 'Helper/Toaster';
import { api } from 'Interceptor';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Form, Row } from 'react-bootstrap';
import GloabalModal from './GlobalModal';
import { useFormik, FormikProvider } from "formik";
import { Formik, Field } from 'formik';
import Userpic from "../../assets/user.png";
import { useDispatch } from 'react-redux'
import { CLOSE_MODAL } from '../../Store/Actions/Modal/Action'
import moment from "moment";
import * as Yup from 'yup';

const GenresModal = () => {
  const { modalReducer: { id, typeOfmodal, slug, data } } = useSelector(state => state);
  const dispatch = useDispatch()

  const [submitted, setSubmitted] = useState(false);
  
  const closeModal = () => {
    dispatch(CLOSE_MODAL({ isOpen: false }))
  }
  const validateobjects = Yup.object().shape({

    title: Yup
      .string().min(2, 'Minimum 2 characters required').max(50, 'Too Long!')
      .required('This field is required'),
    idTitle: Yup
      .string().min(2, 'Minimum 2 characters required').max(50, 'Too Long!')
      .required('This field is required'),
    
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      genreImage: "",
      idTitle: "",

    },
    validationSchema: validateobjects,

    onSubmit: (values) => {
      const requestBody = new FormData()
      requestBody.append("title", values.title)
      requestBody.append("genreImage", values.genreImage)
      requestBody.append("idTitle", values.idTitle)
      
      if (typeOfmodal === 'update-genre') {
        api.headers().method('put', `/genre/update/${id}`, requestBody).then((response) => {
          toastMessage.success("Genre Updated Successfully");
          formik.resetForm({
            values:{
              title: "",
              idTitle: "",
            }
          });
          closeModal();

        }).catch((error) => {
          toastMessage.failure(error?.response?.data?.message || "Genre couldn't be submitted!")
        });
      }

      if (typeOfmodal === 'create-genre') {

        api.headers().method("post", `/genre/create`, requestBody).then((response) => {
          toastMessage.success("Genre Created Successfully");
          closeModal();
          formik.resetForm({
            values:{
              title: "",
              idTitle: "",
            }
          });
        }).catch((error) => {
          toastMessage.failure(error?.response?.data?.message || "Genre couldn't be submitted!")
        });
      }
    },
  });
  useEffect(() => {
    if (id) {
      if (typeOfmodal === "update-genre") {
        formik.values.title = data?.title;
        formik.values.genreImage = data?.genreImage
        formik.values.idTitle = data?.idTitle
      }
    }
  }, [id, typeOfmodal])

  const deleteGenre = async () => {
    const del = await api.headers().method('delete', `/genre/delete/${id}`)
    toastMessage.success("Genre deleted successfully")
    closeModal()
  }
  
  if (typeOfmodal === "delete-genre") {
    return (
      <GloabalModal heading="Delete Genres" >
        <h4>Would you like to delete this Genre?</h4>
        <Button variant="primary" onClick={deleteGenre}>Delete</Button>
        <Button variant="light" onClick={closeModal}>Close</Button>
      </GloabalModal>
    )
  }
  if (typeOfmodal === "create-genre") {
    return (
      <Formik value={formik}>

        <GloabalModal handleParentClose={closeModal} size="lg" heading='Create Genre' >
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="mb-3 col-lg-6">
                <img src={Userpic} className="talent_image " onError={({ currentTarget }) => (currentTarget.src = Userpic)} />
                <label for="genreImage" className='ml-2'>Select a file:</label>
                <input type="file" id="genreImage" accept="image/*" name="genreImage" className='mt-2'  onChange={(e) => { formik.setFieldValue("genreImage", e.currentTarget.files[0]) }} />

              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Title</label>
                <Field className="form-control" type="text" placeholder="Title" name="title" onChange={formik.handleChange}
                  value={formik.values.title} />
                  {formik.errors.title && formik.touched.title ? <div className='field-error'>{formik.errors.title}</div> : null}
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Title(Bahasa)</label>
                <Field className="form-control" type="text" placeholder="Title" name="idTitle" onChange={formik.handleChange}
                  value={formik.values.idTitle} />
                  {formik.errors.idTitle && formik.touched.idTitle ? <div className='field-error'>{formik.errors.idTitle}</div> : null}
              </Form.Group>

            </Row>


            <Button variant="primary" type="submit"
              isLoading={formik.isSubmitting}
              loadingText={"Loading"}
              // onClick={() => {formik.setSubmitting(true);formik.handleSubmit()} }
              >
              Submit
            </Button>
          </Form >
        </GloabalModal>
      </Formik>
    )
  }

  return (

    <>

      <Formik value={formik}>
        <GloabalModal heading={typeOfmodal === 'update-genre' ? "Update Genre" : "Create Genre"}>

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Form.Group className="mb-3 col-lg-6">
                <img src={ data?.genreImage || Userpic} className="talent_image " onError={({ currentTarget }) => (currentTarget.src = Userpic)} />
                <label for="genreImage" className='ml-2'>Select a file:</label>
                <input type="file" id="genreImage" accept="image/*" name="genreImage" className='mt-2' onChange={(e) => { formik.setFieldValue("genreImage", e.currentTarget.files[0]) }} />

              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Title</label>
                <Field className="form-control" type="text" placeholder="Title" name="title" onChange={formik.handleChange}
                  value={formik.values.title} />
                  {formik.errors.title && formik.touched.title ? <div className='field-error'>{formik.errors.title}</div> : null}
              </Form.Group>
              <Form.Group className="mb-3 col-lg-6">
                <label>Title(Bahasa)</label>
                <Field className="form-control" type="text" placeholder="Title" name="idTitle" onChange={formik.handleChange}
                  value={formik.values.idTitle} />
                  {formik.errors.idTitle && formik.touched.idTitle ? <div className='field-error'>{formik.errors.idTitle}</div> : null}
              </Form.Group>

            </Row>


            <Button variant="primary" type="submit"
              isLoading={formik.isSubmitting}
              loadingText={"Loading"}
              onClick={() => {formik.setSubmitting(true);formik.handleSubmit()} }>
              Submit
            </Button>
          </Form >


        </GloabalModal >
      </Formik>



    </>
  )

}

export default GenresModal;
