
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import NotificationToaster from './components/NotificationToaster'
import './app.css'
import { store } from "Store/Store";
import { Provider } from "react-redux";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <NotificationToaster />
      <Switch>
        <Route path='/'
          exact
          render={() => {
            return (
              <Redirect to="/auth/login" />
            )
          }} />
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
