import { api } from 'Interceptor';
import React, { useState } from 'react';
import { toastMessage } from 'Helper/Toaster';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from 'reactstrap';

function CreditsModal(args) {
  const [modal, setModal] = useState(false);
  const [coin, setCoin] = useState(args.coins)

  const body = {
    user: args.user,
    coins: coin
}

 function submit() {
  api.headers().method("put", `/credits`, body).then((response) => {
    console.log("response", response.data);
    toastMessage.success("Credits Edit Successfully");
    args.getData()
    toggle()
    
  }).catch((error) => {
    toastMessage.failure(error?.response?.data?.message || "Credits couldn't be submitted!")
  });
 }

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button color="warning" size="sm" onClick={toggle}>Edit Credits</Button>
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Edit User Credit</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              Name: {args.fullName}
            </FormGroup>
            <FormGroup>
              <Label>Coins</Label>
              <Input id='coin' type='number' value={coin} onChange={(e)=> setCoin(e.target.value)}></Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={submit}>
            Update
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CreditsModal;