import { toastMessage } from 'Helper/Toaster';
import { api } from 'Interceptor';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Form, Col, Row, Container } from 'react-bootstrap'
import Userpic from "../../assets/user.png";
import { useFormik } from "formik";
import { Formik, Field } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import Header from 'components/Headers/Header';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { showNotification } from 'Helper/Functions';
import LoadingOverlay from 'react-loading-overlay';

const AddEditChapters = () => {

  const { slug } = useParams();
  const history = useHistory();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isNavOpen, openNav] = useState(true);
  const [articleData, setArticleData] = useState(null);
  const [previewImage, setPreviewImage] = useState(Userpic);
  const [chapters, setChapters] = useState([]);
  const [selectedChapterIndex, selectChapterIndex] = useState(0);
  const [isActive, setIsActive] = useState(true)

  const validateobjects = Yup.object().shape({

    title: Yup
      .string().min(2, 'Minimum 2 characters required').max(50, 'Too Long!')
      .required('This field is required'),

  });
  const formik = useFormik({
    initialValues: {
      coverPicture: "",
      title: "",
      _id: null,
      content: "",
      sortOrder: "0",
    },
    validationSchema: validateobjects,
    onSubmit: (values) => {

      values.article = articleData._id;
      const requestBody = new FormData();
      const createtBody = new FormData();

      if (!values._id) delete values._id; // To re-affirm that _id is never sent if not there

      requestBody.append("json_data", JSON.stringify(values));
      requestBody.append("coverPicture", values.coverPicture);
      createtBody.append("json_data", JSON.stringify({title : values.title, sortOrder: values.sortOrder, article: articleData._id}));
      createtBody.append("coverPicture", values.coverPicture);

      let request, isNew = false;
      if (values._id) {
        request = api.method('put', `/chapter/update/${values._id}`, requestBody)
      } else {
        request = api.method('post', `/chapter/create`, createtBody)
        isNew = true;

      }

      request.then((response) => {
        const chapterId = response?.data?.chapter?._id || values._id;
        const contentRequest = {
          title: values.title,
          sortOrder: values.sortOrder,
          content: values.content,
        }
        
        api.headers().method('put', `/chapter/update/content/${chapterId}`, contentRequest).then((response) => {
          toastMessage.success(response?.data?.message);
          const updatedChapter = response?.data?.chapter;
          chapters[selectedChapterIndex]._id = chapterId;
          setChapters([...chapters]);
          formik.setSubmitting(false);
          
        }).catch((er) => {
          toastMessage.failure(er?.response?.data?.message || "Chapter Content couldn't be updated!");
        })
        selectChapter(selectedChapterIndex)
      }).catch((res) => {
        toastMessage.failure(res?.response?.data?.message || "Chapter Content couldn't be updated!");
      })
    },
  });

  // Get Article Data from API
  const getArticleData = (slug) => {
    api.headers().method('get', `/article/${slug}`).then(async (response) => {

      const responseData = response?.data?.article;

      if (!responseData) return;
      setArticleData(responseData);

      const chapters = response?.data?.chapters;
      if (chapters.length > 0) {
        setChapters(chapters);
        formik.setValues(chapters[0]);
        getChaptersBySlug(chapters[0]?.slug)
      }
      setIsActive(false)
    })
  }
  const getChaptersBySlug = (chapterSlug) => {
    api.headers().method('get', `/article/${slug}/chapter/${chapterSlug}`).then((res) => {

      formik.setFieldValue('content', res?.data?.chapter?.content)
      formik.setFieldValue('sortOrder', res?.data?.chapter?.sortOrder)
      setIsActive(false)

    }).catch((er) => showNotification(er))
  }
  useEffect(() => {
    if (slug) {
      setIsEditMode(true);
      getArticleData(slug);
    } else {
      setIsEditMode(false)
    }
  }, []);

  const selectChapter = (index) => {
    // Add Form Values in Previous Selected Chapter
    setIsActive(true)
    if (chapters[selectedChapterIndex]) {
      chapters[selectedChapterIndex] = formik.values;
    }
    if( chapters[index]?.title){

      chapters[index]?.content  ? <></> : getChaptersBySlug(chapters[index]?.slug)
    }
    chapters[index]?.content && ( setIsActive(false) )
   // Update states to select new chapter
    
    setPreviewImage(chapters[index]?.coverPicture);
    selectChapterIndex(index);

    // Update Form with selected chapter data
    if (Object.keys(chapters[index]).length > 0) {
      formik.setValues(chapters[index]);
    } else {
      formik.resetForm();
      setIsActive(false)
    }
  }

  const addChapter = () => {
    const lastIndex = chapters.length - 1;
    chapters.push({});
    setChapters([...chapters]);

    // setTimeout(() => {
    //   selectChapter(lastIndex+1);
    // })
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Formik value={formik}>
          <Form className='page--form' onSubmit={formik.handleSubmit}>
            <Row className='justify-content-end mb-3'>
              <Col>
                <Button variant="info" type="button" onClick={addChapter}>
                  Add new Chapter
                </Button>
              </Col>
            </Row>
            <LoadingOverlay
              active={isActive}
              spinner
              text='Loading your content...'
            >
              {
                chapters.length > 0 && (
                  <>
                    <Row>
                      <Form.Group className="mb-3 col-lg-6">
                        <label>Title</label>
                        <Field className="form-control" type="text" placeholder="Title" name="title" onChange={formik.handleChange}
                          value={formik.values.title} onBlur={formik.handleBlur} />
                        {formik.errors.title && formik.touched.title ? <div className='field-error'>{formik.errors.title}</div> : null}

                      </Form.Group>
                      <Form.Group className="mb-3 col-lg-12">
                        <label>Content</label>
                        <ReactQuill className='iyg-editor' theme="snow" value={formik.values.content} onChange={(e) => { formik.setFieldValue('content', e) }} />
                      </Form.Group>

                      <Form.Group className="mb-3 col-lg-6">
                        <label>Sort Order</label>
                        <Field className="form-control" type="text" placeholder="Sort Order" name="sortOrder" onChange={formik.handleChange}
                          value={formik.values.sortOrder} onBlur={formik.handleBlur} />
                      </Form.Group>
                    </Row>

                    <Button variant="primary" type="submit"
                      isLoading={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                      loadingText={"Loading"}
                      onClick={() => {   formik.handleSubmit()}}>
                      Submit
                    </Button>
                  </>
                )
              }
            </LoadingOverlay>
          </Form >
        </Formik>

        <div className={`floating-nav ${isNavOpen ? 'is--open' : ''}`}>
          <ul>
            {chapters.map((x, i) => (
              <li onClick={() => { selectChapter(i) }} className={`${i === selectedChapterIndex ? 'is--active' : ''}`} >
                <h3>{x?.title || "Untitled Chapter"}</h3>
                <div className='d-flex'>
                  <span>{x?.status}</span>
                </div>
              </li>
            ))}
            {chapters.length === 0 && (
              <p className='no-data'>There are no chapters created yet, <a onClick={addChapter}>Create a chapter.</a></p>
            )}
          </ul>
          <div className="nav-toggle" onClick={() => openNav(!isNavOpen)}>
            <i className={`fas ${isNavOpen ? 'fa-chevron-right' : 'fa-chevron-left'}`}></i>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AddEditChapters;
