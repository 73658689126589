import React from 'react'
import { useState } from 'react';
import { Button, Toast } from 'react-bootstrap';
import { Form, Row, Container } from 'react-bootstrap'
import Userpic from "../../assets/user.png";
import { useFormik } from "formik";
import { Formik, Field } from 'formik';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useHistory, useParams } from 'react-router-dom';
import Header from 'components/Headers/Header';
import { useEffect } from 'react';
import { api } from 'Interceptor';
import { showNotification } from 'Helper/Functions';
import { toastMessage } from 'Helper/Toaster';

const ChapterAccess = () => {
  
  const [settings, setSettings] = useState();

  // const handleOnChange = () => {
  //   setIsChecked(!isChecked);
  // };

  const formik = useFormik({
    initialValues: {
      _id: "",
      freeChapterAccess: "",
      // forceSocialAuth: true
    },

    onSubmit: (values) => {

      values._id = settings[0]?._id
      api.headers().method('put', `/settings/update/${values._id}`, values).then((res) => {
        formik.setSubmitting(false);
        toastMessage.success("Settings Updated")
      }).catch((er) => {

        showNotification(er)
      })
    },
    
  });
  useEffect(() => {
    api.headers().method('get', '/settings').then((response) => {
      setSettings(response?.data?.settingss)
      const responseData = response?.data?.settingss
      formik.setFieldValue("freeChapterAccess", responseData[0]?.freeChapterAccess)
      // formik.setFieldValue("forceSocialAuth", responseData[0]?.forceSocialAuth)

    }).catch((er) => showNotification(er))
  }, [])
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Formik value={formik}>
          <Form className='page--form' onSubmit={formik.handleSubmit}>
            <Row>

              <Form.Group className="mb-3 col-lg-6">
                <label>Free Chapter Access</label>
                <Field className="form-control" type="number" placeholder="Title" name="freeChapterAccess" onChange={formik.handleChange}
                  value={formik.values.freeChapterAccess} />
              </Form.Group>

              {/* <Form.Group className="mb-3 col-lg-12">
                <label style={{marginRight: "10px",}}>Force Social Authentication</label>
                <input type="checkbox"
                 id="forceSocialAuth"
                 name="forceSocialAuth"
                 value={formik.values.forceSocialAuth}
                checked={formik.values.forceSocialAuth}
                onChange={formik.handleChange} />
              </Form.Group> */}

              <Form.Group className="mb-3 col-lg-12">

                <Button variant="primary" type="submit"
                  isLoading={formik.isSubmitting}
                  loadingText={"Loading"}
                  disabled={JSON.parse(localStorage.getItem('superadmin-session'))?.user?.role !== "superadmin" ? true : false}
                  onClick={() => {formik.setSubmitting(true);formik.handleSubmit()}}>
                  Submit
                </Button>
              </Form.Group>
            </Row>


          </Form >
        </Formik>
      </Container>
    </>
  )
}

export default ChapterAccess